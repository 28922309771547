import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "~/components/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as S from "./styled";
import Icon from "~/components/Icons";
import Button from "~/components/Buttons";
import { Collapse, IconButton, makeStyles } from "@material-ui/core";
import useRequestData from "~/utils/hooks/useRequestData";
import clsx from "clsx";
import { resetFilialSuccess, resetStatusUsuarioSuccess } from "~/store/modules/filter/actions";
import { useDispatch } from "react-redux";

export default function Filter({
  status,
  filial,
  handleOnChange,
  handleAddModal,
}) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(true);

  const requestFilials = useRequestData("/filials");
  const filials = requestFilials && requestFilials.rows;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <S.Title>Usuários</S.Title>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <S.More>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
              >
                <Icon
                  name="arrow-circle-down-outline"
                  color="#656E8C"
                  size={18}
                />
              </IconButton>
              <p>
                {expanded
                  ? "Remover e Ocultar Filtros"
                  : "Adicionar e Exibir Filtros"}
              </p>
            </S.More>
            <Button
              variant="contained"
              color="primary"
              style={{
                color: "#fff",
                marginLeft: "10px",
              }}
              icon={<Icon name="plus-circle" color="#fff" size={17} />}
              onClick={handleAddModal}
            >
              Adicionar Usuário
            </Button>
          </div>
        </div>
      </S.Header>

      <Collapse
        in={expanded}
        timeout="auto"
        classes={{
          wrapper: classes.alignment,
        }}
        unmountOnExit
      >
        <S.Filter>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Select
                fullwidth
                name="filial"
                label={"Filial"}
                value={filial}
                reset
                onClear={() => dispatch(resetFilialSuccess())}
                onChange={(e) => handleOnChange(e.target.value, 'filial')}
              >
                {filials &&
                  filials.map(({ id, nome }) => (
                    <MenuItem value={id}>
                      <S.Item> {nome.toLowerCase()}</S.Item>
                    </MenuItem>
                  ))}
              </Select>
            </Grid>

            <Grid item xs={4}>
              <Select
                label="Status"
                name="status"
                id="status"
                reset
                onClear={() => dispatch(resetStatusUsuarioSuccess())}
                value={status}
                onChange={(e) => handleOnChange(e.target.value, 'status_usuario')}
              >
                {["ATIVO", "INATIVO"].map(item => (
                  <MenuItem value={item}>
                    <S.Item>{item.toLowerCase()}</S.Item>
                  </MenuItem>
                ))}
              </Select>
            </Grid>

          </Grid>
        </S.Filter>
      </Collapse>
    </S.Wrapper>
  );
}

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    margin: 0,
    padding: 0,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  wrapper: {
    display: "inherit !important",
    width: "100%",
  },
}));
