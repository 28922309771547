import React, { useEffect, useState } from "react";
import { auth } from "./firebase";
import api from "../services/api";
import Loading from "~/components/Loading";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        user.getIdToken().then((token) => {
          api.defaults.headers.Authorization = `Bearer ${token}`;
          setPending(false);
        });
      } else {
        setPending(false);
      }
    });
  }, []);

  if (pending) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};


// import React, { useEffect, useState } from "react";
// import { auth } from "./firebase";
// import Loading from '~/components/Loading'

// export const AuthContext = React.createContext();


// export const AuthProvider = ({ children }) => {
//     const [currentUser, setCurrentUser] = useState(null);
//     const [pending, setPending] = useState(true);

//     useEffect(() => {
//         auth.onAuthStateChanged((user) => {
//             setCurrentUser(user)
//             if (user) {
//                 user.getIdToken().then(token => {
//                     setPending(false)
//                 })
//             } else {
//                 setPending(false)
//             }

//         });
//     }, []);

//     if (pending) {
//         return <Loading />
//     }

//     return (
//         <AuthContext.Provider
//             value={{
//                 currentUser
//             }}
//         >
//             {children}
//         </AuthContext.Provider>
//     );
// };