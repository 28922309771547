import styled from 'styled-components'

export const Title = styled.h1`
  font-size: 24px;
  color: #494f65;
  margin-top: -15px;
  margin-bottom: 10px;
  font-weight: 900;
  letter-spacing: 1.1px;
`
export const SubTitle = styled.p`
  font-size: 24px;
  color: #494f65;
  margin-top: -15px;
  margin-bottom: 5px;
  letter-spacing: 1.1px;
  
`

export const Header = styled.div`
  padding: 22px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
`
export const Criticidade = styled.div`
  margin-top: -7px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  div{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
    background: ${props => props.color}
  }

  p{
    color: ${props => props.color};
    font-size: 13px;
  }
`
