import React, { useState } from "react";
import * as S from "./styled";
import Icon from "~/components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { resetDateSuccess, setFilter } from "~/store/modules/filter/actions";
import { toast } from "react-toastify";
import DateModal from "./CalendarModal/index";

const InputDateRange = ({one}) => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter);

  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchorEl = Boolean(anchorEl);
  const [initialRange, setInitialRange] = useState([]);
  const [finalRange, setFinalRange] = useState([]);
  const [initialMonth, setInitialMonth] = useState(null);
  const [initialYear, setInitialYear] = useState(null);
  const [finalMonth, setFinalMonth] = useState(null);
  const [finalYear, setFinalYear] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  function formatData(month) {
    const newMonth = months.filter((item, index) => index === month);
    return newMonth;
  }

  const handleInitialDate = (date) => {
    setInitialRange(date);
    if (date) {
      const mesInicial = new Date(date).getMonth();
      const anoInicial = new Date(date).getFullYear();
      setInitialMonth(mesInicial);
      setInitialYear(anoInicial);
    }
    if (one) handleFinalDate(date)
  };

  const handleFinalDate = (date) => {
    setFinalRange(date);
    if (date) {
      const mesFinal = new Date(date).getMonth();
      const anoFinal = new Date(date).getFullYear();
      setFinalMonth(mesFinal);
      setFinalYear(anoFinal);
    }
  };

  const currentYear = new Date().getFullYear();

  const submitDate = () => {
    if (initialMonth >= 0 && initialYear && finalYear <= currentYear) {
      const label = `${formatData(initialMonth)}/${initialYear} - ${formatData(
        finalMonth
      )}/${finalYear}`;

      dispatch(
        setFilter({
          ...filter,
          anoFinal: finalYear,
          anoInicial: initialYear,
          mesFinal: finalMonth,
          mesInicial: initialMonth,
          label: label,
        })
      );
      setAnchorEl(null);
    } else {
      clearDate();
      toast.error("Data inválida");
    }
  };

  const clearDate = () => {
    setInitialRange([]);
    setFinalRange([]);
    setInitialMonth(null);
    setInitialYear(null);
    setFinalMonth(null);
    setFinalYear(null);
    dispatch(resetDateSuccess());
  };
  return (
    <>
      <S.DateContainer>
        <span>{filter.label ? filter.label : "Filtrar por Data"}</span>
        <S.Div>
          <S.StyledButton onClick={clearDate}>
            <Icon name="close" color="#95AAC9" style={{ height: "20px" }} />
          </S.StyledButton>
          <S.StyledButton
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Icon
              name="calendar-outline"
              color="#95AAC9"
              style={{ height: "20px" }}
            />
          </S.StyledButton>
        </S.Div>
      </S.DateContainer>
      <DateModal
        one={one}
        handleClick={handleClick}
        anchorEl={anchorEl}
        open={openAnchorEl}
        handleClose={() => setAnchorEl(null)}
        handleFinalDate={handleFinalDate}
        handleInitialDate={handleInitialDate}
        initialValue={initialRange}
        finalValue={finalRange}
        onSubmit={submitDate}
        clearDate={clearDate}
      />
    </>
  );
};
export default InputDateRange;
