import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: space-between;
  margin: 0px 0px -16px 20px;
`

export const Title = styled.h1`
  font-size: 18px;
  margin-top: -25px;
  margin-left: 5px;
  color:#494F65;
`