import React from 'react'
import * as S from './styled'

const Auth = ({ children }) => {
  
  return(
    <S.Container>
      {children}
    </S.Container>
  )
}

export default Auth
