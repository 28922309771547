import React, { useState } from "react";
import * as S from "./styled";
import { Grid, TextField, Button, InputAdornment } from "@material-ui/core";
import { signInRequest } from "../../store/modules/auth/actions";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Onisys from "../../images/logo.svg";

const Auth = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [currentPage, setCurrentPage] = useState(true);

  const dispatch = useDispatch();

  const changePage = () => {
    setCurrentPage(!currentPage);
  };

  const handleSubmit = async () => {
    try {
      const data = { email, password };

      const schema = Yup.object().shape({
        email: Yup.string()
          .email()
          .required("Esse campo é obrigatório."),
        password: Yup.string()
          .min(6)
          .required("Esse campo é obrigatório."),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      dispatch(signInRequest(data));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <S.Container>
      <S.GridContainer>
        <S.GridItem>
          <S.Image src={Onisys} alt={"Onisys"} />
        </S.GridItem>
        <S.GridItem>
          <S.Card>
            <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
              <S.Title>
                {currentPage ? "ACESSO TRANSPORTADORA" : "RECUPERAR SENHA"}
              </S.Title>
              {!currentPage && (
                <Grid
                  item
                  xs={12}
                  style={{ width: "100%", marginBottom: "13px" }}
                >
                  <S.Password>Esqueceu sua senha?</S.Password>
                  <S.Email>
                    Coloque seu email e enviaremos um link para alterá-la.
                  </S.Email>
                </Grid>
              )}
              <Grid item xs={12} style={{ width: "100%" }}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  type="email"
                  variant="outlined"
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <AlternateEmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {currentPage && (
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    variant="outlined"
                    fullWidth
                    label="Senha"
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <LockOpenIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  width: "100%",
                  margin: 0,
                  justifyContent: "space-between",
                }}
              >
                <div onClick={changePage} style={{ cursor: "pointer" }}>
                  <S.Subtitle>
                    {currentPage ? "Esqueci minha senha" : "Fazer Login"}
                  </S.Subtitle>
                </div>
                <Button
                  style={{
                    color: "#fff",
                    width: "116px",
                    height: "50px",
                    marginTop: "15px",
                  }}
                  onClick={handleSubmit}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  <S.ButtonTitle>
                    {currentPage ? "Entrar" : "Enviar"}
                  </S.ButtonTitle>
                </Button>
              </Grid>
            </Grid>
          </S.Card>
        </S.GridItem>
      </S.GridContainer>
    </S.Container>
  );
};

export default Auth;
