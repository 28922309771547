import React from 'react'
import * as S from './styled'
import { Tabs, Tab, withStyles } from '@material-ui/core'
import Icon from '~/components/Icons'

const DashTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    minWidth: 70,
    transition: '0s',
    marginBottom: '-40px',
    padding: '5px 10px 5px 10px',
    margin: '6px 0px 0px 0px',
    fontSize: '14px !important',
    color: '#494F65',
    '&:hover': {
      color: '#BDCC30',
      opacity: 1,
      minWidth: 70,
    },
    '&$selected': {
      color: '#BDCC30',
      minWidth: 70,
    },
    '&:focus': {
      color: '#BDCC30', 
      minWidth: 70,     
    },
  },
}))((props) => <Tab disableRipple {...props} />)

const AntTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    minWidth: 70,
    transition: '0s',
    marginBottom: '-40px',
    padding: '1px 5px 1px 5px',
    margin: '6px 35px 0px 0px',
    fontSize: '14px !important',
    color: '#494F65',
    '&:hover': {
      color: '#BDCC30',
      opacity: 1,
      minWidth: 70,
    },
    '&$selected': {
      color: '#BDCC30',
      minWidth: 70,
    },
    '&:focus': {
      color: '#BDCC30', 
      minWidth: 70,     
    },
    "&:last-child": {
      alignSelf: 'flex-end !important'
    }
  },
}))((props) => <Tab disableRipple {...props} />)


export default function TabComponent({ handleInfo, value, mini, onChange, items, ...props }) {
  return (
    <S.Wrapper>
      <Tabs value={value} onChange={onChange} {...props}>
        {items && items.map((i) => mini ? 
          (<DashTab key={i} label={<span style={{marginTop: '10px'}}>{i}</span>}/>) : 
          (<AntTab key={i} label={!handleInfo ? (<span style={{marginTop: '10px'}}>{i}</span>) :
            (
              <S.Span>
                {i}
                <S.InfoIcon onClick={() => handleInfo(i)}>
                  <Icon name='alert-circle-outline' size={15} fill='#494F65'/>
                </S.InfoIcon>  
              </S.Span>
            )
          }/>)
        )}
      </Tabs>
      <div style={{borderBottom: '1px solid #EDF2F9'}}/>
    </S.Wrapper>
  )
}