import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 100% 50%;
  background-size: 100% 100%;
  background: transparent linear-gradient(0deg, #373f54 0%, #0b0d1160 100%) 0%
    0% no-repeat padding-box;
  align-items: center;
  justify-content: center;
  opacity: 1;
`;

export const Card = styled.div`
  padding: 20px;
  border-radius: 10px;
  max-width: 440px;
  width: 100%;
  background: #f9fbfd 0% 0% no-repeat padding-box;
  width: 375px;
  height: 422px;
  margin-right: 18%;
  box-shadow: 0px 4px 10px #00000080;
  @media (max-width: 800px) {
    max-width: 350px;
  }
`;

export const Title = styled.h6`
  color: #494f65;
  font-size: 18px;
  font-weight: 900;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 28px;
`;

export const Subtitle = styled.p`
  color: #656e8c;
  font-size: 12px;
  text-decoration: underline;
  margin-top: 37px;
`;

export const ButtonTitle = styled.p`
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
`;
export const Image = styled.img`
  height: 50px;
  z-index: 999;
`;
export const GridItem = styled.div`
  margin-right: 15%;
  @media (max-width: 800px) {
    margin-right: 0;
    margin-bottom: 30px;
  }
`;

export const GridContainer = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Password = styled.p`
  font-size: 14px; font-weight: bold;
`
export const Email = styled.p`
  font-size: 14px;  
  margin-top: 1px;
`