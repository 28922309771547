import styled from 'styled-components'

export const Wrapper = styled.div`

.MuiTab-textColorPrimary {
    color: #494F65 !important;
    font-weight: 900;
    opacity: 0.5;
}

.MuiTab-textColorPrimary.Mui-selected {
    opacity: 1;
}

`

export const InfoIcon = styled.button`
  display: none;
  outline: none;
  border: none;
  background-color: #F9FBFD;
  border-radius: 50%;
  margin-left: 3px;
`

export const Span = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  &:hover ${InfoIcon} {
    display: flex;
  }
`
