
export default {
  primary: {
    light: '#BDCC3026',
    main: '#BDCC30',
  },
  secondary: {
    light: 'rgba(246, 78, 96, 0.15)',
    main: '#F64E60',
  },
  background: {
    main: '#F9FBFD',
    secondary: "#EDF2F9",
    white: '#fff'
  },
  info: {
    main: '#494F65',
    secondary: '#373F54',
  },
  grey: {
    main: '#95AAC9',
  }
};