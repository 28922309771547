import React from 'react';
import theme from '~/styles/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import Routes from './routes';
import history from './services/history';
import { store, persistor } from './store';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from '~/styles/global'
import { AuthProvider } from './utils/auth'

function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Router history={history}>
              <Routes />
              <ToastContainer autoClose={3000} />
              <GlobalStyle />
            </Router>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </AuthProvider>
  );
}

export default App;
