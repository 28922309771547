import styled from 'styled-components';

export const Date = styled.div`
    .MuiFormHelperText-contained {
    display: none;
     label{
         color: #95AAC9 !important;
     }

     width: 100%;
     position: relative;
}
`

export const CloseButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 40px;
  top: 17px;
  cursor: pointer;
  z-index: 999;
`
