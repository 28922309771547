import React, { useState } from 'react'
import * as S from './styled'
import {
  Grid,
  makeStyles,
  Collapse,
  IconButton,
  MenuItem,
} from '@material-ui/core'
import Icon from '~/components/Icons'
import Select from '~/components/Select'
import InputDateRange from '../../../components/Inputs/InputDateRange/index'
import clsx from 'clsx'
import { resetAreaSuccess, resetFilialSuccess } from '~/store/modules/filter/actions'
import { useDispatch } from 'react-redux'

export default function Filter({ handleOnChange, title, filial, filials, area }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  
  const areas = filials.filter(item => item.id === filial).map(filial => filial.areas)[0]

  return (
    <S.Wrapper>
      <S.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <S.Title>{title}</S.Title>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <S.More>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
              >
                <Icon
                  name='arrow-circle-down-outline'
                  color='#656E8C'
                  size={18}
                />
              </IconButton>
              <p>
                {expanded
                  ? 'Remover e Ocultar Filtros'
                  : 'Adicionar e Exibir Filtros'}
              </p>
            </S.More>
          </div>
        </div>
      </S.Header>
      <Collapse
        in={expanded}
        timeout='auto'
        classes={{
          wrapper: classes.alignment,
        }}
        unmountOnExit
      >
        <S.Filter>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputDateRange />
            </Grid>
            <Grid item xs={4}>
              <Select
                fullwidth
                name='filial'
                label={'Filial'}
                value={filial}
                reset
                onClear={() => dispatch(resetFilialSuccess())}
                onChange={e => handleOnChange(e.target.value, 'filial')}
              >
                {filials &&
                  filials.map(({ id, nome }) => (
                    <MenuItem key={id} value={id}>
                      <S.Item>{nome.toLowerCase()}</S.Item>
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Select
                fullwidth
                name='area'
                label={'Area'}
                value={area}
                reset
                onClear={() => dispatch(resetAreaSuccess())}
                onChange={e => handleOnChange(e.target.value, 'area')}
              >
                {areas &&
                  areas.map(area => {
                    return (
                      <MenuItem key={area.id} value={area.id}>
                        <S.Item>{area.nome.toLowerCase()}</S.Item>
                      </MenuItem>
                    )
                  })}
              </Select>
            </Grid>
          </Grid>
        </S.Filter>
      </Collapse>
    </S.Wrapper>
  )
}

const useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    margin: 0,
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  wrapper: {
    display: 'inherit !important',
    width: '100%',
  },
}))
