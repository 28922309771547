import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  padding: 50px;

  h3 {
    color: #494f65;
    font-size: 30px;
    font-weight: bold;
    height: 43px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    color: #95aac9;
    height: 23px;
  }
`

export const Image = styled.img`
  margin-top: 50px;
  width: 600px;
`
