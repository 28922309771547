import React, { useState, useEffect } from "react";
import * as S from "./styled";
import Table from "~/components/Table";
import Filter from "./Filtros";
import { useDispatch, useSelector } from "react-redux";
import { columns } from "./columns";
import { setFilter } from "../../store/modules/filter/actions";
import api from "~/services/api";

const Dispositivos = () => {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.filter);

  const [devicesData, setDevicesData] = useState(null);
  const filteredData = null;

  const requestDevice = async (data) => {
    const response = await api.get("/devices", { params: data });
    setDevicesData(response.data);
  };

  useEffect(() => {
    const filters = {
      area: query.area,
      filial: query.filial,
    };
    requestDevice(filters);
  }, [query]);

  columns.forEach((i) => {
    if (i.accessor === "descricao") {
      i.Cell = ({ row }) => {
        const { descricao } = row.original ? row.original : "";
        return (
          <S.Name>
            <h1>{descricao && descricao.split(".")[0]}</h1>
          </S.Name>
        );
      };
    } else if (i.accessor === "filial") {
      i.Cell = ({ row }) => {
        return <div>{row.original.filial && row.original.filial.nome}</div>;
      };
    } else if (i.accessor === "area") {
      i.Cell = ({ row }) => {
        return <div>{row.original.area && row.original.area.nome}</div>;
      };
    } else if (i.accessor === "tipo") {
      i.Cell = ({ row }) => (
        <div>{row.original.tipo ? row.original.tipo : ""}</div>
      );
    } else if (i.accessor === "local") {
      i.Cell = ({ row }) => (
        <div>{row.original.local ? row.original.local : ""}</div>
      );
    } else if (i.accessor === "processos") {
      i.Cell = ({ row }) => (
        <div>{row.original.processos ? row.original.processos : ""}</div>
      );
    }
  });

  const handleOnChange = (value, name) => {
    if (value) {
      if (name === "filial") {
        dispatch(
          setFilter({
            ...query,
            filial: value,
          })
        );
      } else if (name === "area") {
        dispatch(
          setFilter({
            ...query,
            area: value,
          })
        );
      }
    }
  };

  return (
    <React.Fragment>
      <Filter
        area={query.area}
        filial={query.filial}
        handleOnChange={handleOnChange}
        title={"Dispositivos"}
      />
      {devicesData && (
        <S.Container>
          <Table
            noPagging
            style={{ marginTop: 22 }}
            columns={columns}
            data={filteredData ? filteredData : devicesData}
          />
        </S.Container>
      )}
    </React.Fragment>
  );
};

export default Dispositivos;
