import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 16px;
    font-weight: 900;
    color: #494f65;
  }

  p {
    font-size: 12px;
    font-weight: 600;
    color: #95aac9;
  }
`;
export const Percentage = styled.div`
  display: flex;
  justify-content: center;
  color: #2e3240;
  font-weight: 700;
  font-size: 12px;
  margin-left: -20px;
`;
export const Status = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Plate = styled.div`
  margin-top: 17px;
  margin-bottom: 10px;
`;

export const UnChecked = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  width: 22px;
  height: 22px;
  border: 1px solid #dde5f0;
  background-color: #f9fbfd;
  margin: 0px 1px;

  ${({ check }) =>
    check &&
    `
    background: #b3e5fc;
  `}

${({ color }) =>
  color === 1 &&
  `
    background: #F64E60;
  `}

${({ color }) =>
  color === 2 &&
  `
    background: #ffff00;
  `}

${({ color }) =>
  color === 3 &&
  `
    background: #69f0ae;
  `}
`;

export const StatusWrapper = styled.div``;

export const Label = styled.div`
  position: absolute;
  top: -30px;
  right: 15px;
  z-index: 9999;
  background: #fff;
  padding: 5px;
  display: none;
  border-radius: 5px;
  width: 210px;

  ${StatusWrapper}:hover & {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

`;

export const NumbersHeader = styled.div`
  width: 22px;
  height: 22px;
  color: "#656E8C";
  text-align: center;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MonthDay = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: -10px;

  div {
    display: flex;
    justify-content: center;
    width: 22px;
    height: 22px;
  }
`;
export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
`;
