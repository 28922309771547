import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  .MuiTab-textColorPrimary {
    color: #494f65 !important;
    font-weight: 900;
    opacity: 0.5;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    opacity: 1;
  }
`;

export const Header = styled.div`
  padding: 22px 0px 0px 0px;
`;

export const Main = styled.div`
  background: #edf2f9;
  padding: 22px;
`;

export const Footer = styled.div`
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const More = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    color: #656e8c;
    font-weight: 900;
    margin-left: 5px;
  }
`;

export const Button = styled.button`
  width: 64px;
  height: 31px;
  background: #dde5f0 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #494f65;
  font-weight: 900;
  border: none;
  margin-left: 15px;

  ${({ filter }) =>
    filter &&
    `
    background: #494F65;
    color: #fff;
  `}
`;

export const Title = styled.h1`
  font-size: 24px;
  color: #494f65;
  margin-top: -15px;
  margin-bottom: 5px;
  font-weight: 900;
  letter-spacing: 1.1px;
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf2f9;
  width: 100%;
  height: 170px;
  margin-top: 22px;
  padding: 0px 5px;
  border-radius: 4px;
`;

export const Item = styled.p`
  text-transform: capitalize;
  font-size: 17px;
`;
