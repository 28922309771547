import React, { useState, useEffect } from "react";
import Icon from "~/components/Icons";
import Input from "~/components/Input";
import Select from "~/components/Select";
import { makeStyles } from "@material-ui/core/styles";
import { Button, useTheme, Grid, Modal, MenuItem } from "@material-ui/core";
import * as S from "./styled";
import { useFormik } from "formik";
import { requestFilials } from "../services";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { SaveModal as StatusModal } from "~/components/Cards/Save/SaveModal";
import { SaveModal as Save } from "~/components/Cards/Save/SaveModal";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "500px",
    height: "550px",
    backgroundColor: "#FFFFFF",
    border: "0px 5px 20px #B0C3D329;",
    borderRadius: "4px",
    padding: "25px 35px",
    margin: "0 auto",
  },
  root: {
    flexGrow: 1,
  },
}));

export default function ModalDeviation({
  user,
  newUser,
  handleClose,
  handleUpdateUser,
  open,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [modalStyle] = useState(getModalStyle);
  const [filials, setFilials] = useState(null);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openSaveModalRemove, setOpenSaveModalRemove] = useState(false);
  const [userUpdated, setUserUpdated] = useState(null);

  const handleCloseSaveModal = () => {
    setOpenSaveModal(false);
    setUserUpdated(null);
  };

  const fetch = async () => {
    const response = await requestFilials();
    setFilials(response.data.rows);
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleSubmitData = async () => {
    if (userUpdated) {
      if (newUser) {
        const res = await api.post(`/users`, userUpdated);
        if (res?.data?.success) toast.success("Usuário criado com sucesso");
        else {
          if (res?.data?.message) toast.error(res?.data?.message);
          else toast.error("Erro ao criar novo usuários");
        }
        handleUpdateUser();
      } else {
        const res = await api.put(`/users/${user.id}`, userUpdated);
        if (res?.data?.success) toast.success("Usuário atualizado com sucesso");
        else {
          if (res?.data?.message) toast.error(res?.data?.message);
          else toast.error("Erro ao criar novo usuários");
        }
        handleUpdateUser();
      }
    } else {
      toast.error("Erro ao criar usuário");
    }
    handleCloseSaveModal();
    handleClose();
  };

  const disableUser = async () => {
    let newAtive = { ...user };
    newAtive.status = "INATIVO";
    if (user) {
      await api.put(`/users/${user.id}`, newAtive);
      toast.success("Usuário desabilitado com sucesso");
    } else {
      toast.error("Erro ao desabilitar usuário");
    }
    handleCloseSaveModal();
    handleClose();
    handleUpdateUser();
  };

  const activateUser = async () => {
    let newAtive = { ...user };
    newAtive.status = "ATIVO";
    newAtive.matricula = newAtive.matricula.toString();
    if (user) {
      await api.put(`/users/${user.id}`, newAtive);
      toast.success("Usuário reativado com sucesso");
    } else {
      toast.error("Erro ao reativar usuário");
    }
    handleCloseSaveModal();
    handleClose();
    handleUpdateUser();
  };

  const formik = useFormik({
    initialValues: {
      nome: user.nome ? user.nome : "",
      email: user.email ? user.email : "",
      matricula: user.matricula ? user.matricula : "",
      id_da_filial: user.id_da_filial ? user.id_da_filial : "",
      funcao: user.funcao ? user.funcao : "",
      nivel: user.nivel ? user.nivel : "",
      telefone_de_contato: user.telefone_de_contato
        ? user.telefone_de_contato
        : "",
      status: user.status ? user.status : "ATIVO",
      provider: user.provider ? user.provider : false,
      id_da_empresa: user.id_da_empresa ? user.id_da_empresa : "",
    },
    onSubmit: (values) => {
      setUserUpdated(values);
      setOpenSaveModal(true);
    },
  });

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <S.Header>
        <S.Title>{!newUser ? "Editar Usuário" : "Adicionar Usuário"}</S.Title>
        <S.RightHeader>
          <S.CloseButton
            startIcon={<Icon name="close" color="#223345" />}
            onClick={handleClose}
            variant="inherit"
            style={{ backgroundColor: "transparent" }}
          />
        </S.RightHeader>
      </S.Header>
      <S.Form onSubmit={formik.handleSubmit}>
        <S.Main className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                required
                type="text"
                label="Nome"
                name="nome"
                id="nome"
                value={formik.values.nome}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required
                type="email"
                label="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                type="text"
                label="Matrícula"
                name="matricula"
                value={formik.values.matricula}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                type="text"
                label="Função"
                name="funcao"
                value={formik.values.funcao}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                required
                fullwidth
                label="Filial"
                name="id_da_filial"
                value={formik.values.id_da_filial}
                onChange={formik.handleChange}
              >
                {filials &&
                  filials.map((r) => (
                    <MenuItem value={r.id}>{r.nome}</MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                type="tel"
                label="Telefone"
                name="telefone_de_contato"
                placeholder={"(31) 9 9999-9999"}
                value={formik.values.telefone_de_contato}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                required
                fullwidth
                label="Nível"
                name="nivel"
                value={formik.values.nivel}
                onChange={formik.handleChange}
              >
                {[1, 2, 3, 4].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </S.Main>
        <S.Footer>
          {!newUser ? (
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setOpenSaveModalRemove(true)}
            >
              {user.status === "ATIVO"
                ? "Desativar Usuário"
                : "Reativar Usuário"}
            </Button>
          ) : (
            <div />
          )}
          <S.RightFooter>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#DDE5F0",
                color: theme.palette.info.main,
              }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{
                color: "#fff",
                marginLeft: "10px",
              }}
            >
              Salvar
            </Button>
          </S.RightFooter>
        </S.Footer>
      </S.Form>
    </div>
  );

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
      <Save
        onClick={handleSubmitData}
        open={openSaveModal}
        handleClose={handleCloseSaveModal}
        title={"usuário"}
        subtitle={"do usuário"}
      />
      <StatusModal
        onClick={user.status === "ATIVO" ? disableUser : activateUser}
        open={openSaveModalRemove}
        handleClose={() => setOpenSaveModalRemove(false)}
        title={"usuário"}
        subtitle={"do usuário"}
      />
    </div>
  );
}
