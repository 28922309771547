export const SET_FILTER = "@filter/SET_FILTER";
export const SET_FILTER_SUCCESS = "@filter/SET_FILTER_SUCCESS";

export const RESET_DATE_SUCCESS = "@filter/RESET_DATE_SUCCESS";
export const RESET_FILIAL_SUCCESS = "@filter/RESET_FILIAL_SUCCESS";
export const RESET_AREA_SUCCESS = "@filter/RESET_AREA_SUCCESS";
export const RESET_CRITICALITY_SUCCESS = "@filter/RESET_CRITICALITY_SUCCESS";
export const RESET_RESPONSIBLE_SUCCESS = "@filter/RESET_RESPONSIBLE_SUCCESS";
export const RESET_INFRACTION_SUCCESS = "@filter/RESET_INFRACTION_SUCCESS";
export const RESET_VIAGEM_SUCCESS = "@filter/RESET_VIAGEM_SUCCESS";
export const RESET_STATUS_USUARIO_SUCCESS = "@filter/RESET_STATUS_USUARIO_SUCCESS";

export const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];