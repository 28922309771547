const paletteColors = [
  "#0C12F2",
  "#BABF1C",
  "#656E8C",
  "#2E3240",
  "#70AED4",
  "#486F88",
  "#4AF20C",
  "#6D50C7",
  "#008F7A",
  "#3B4D8C",
  "#04004A",
  "#486F88",
  "#B0C6D4",
  "#9CA9D9",
];
const criticalColors = ["#3699FF", "#FFA801", "#F64E60"];
const statusColors = ["#2E3240", "#BABF1C", "#656E8C"];

export const tabsArray = [
  "PROCEDIMENTOS DA DIREÇÃO SEGURA",
  "RESPEITA OS PROCEDIMENTOS DA EMPRESA",
  "ANTES DO INÍCIO DA VIAGEM",
  "UTILIZAÇÃO DE CÂMERAS EMBARCARDAS",
  "APÓS A VIAGEM",
  "PERGUNTAS ADICIONAIS (NÃO OBRIGATÓRIAS)",
];

export const radarOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      distributed: true,
    },
  },
  colors: paletteColors,
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: "category",
    labels: {
      style: {
        colors: paletteColors,
      },
    },
  },
  legend: {
    show: false,
  },
};

export const optionsCriticality = {
  chart: {
    type: "bar",
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: false,
    },
  },
  colors: criticalColors,
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: "30%",
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: "11px",
    },
  },
  stroke: {
    width: 2,
    colors: ["#fff"],
  },
  xaxis: {
    categories: [""],
    labels: {
      show: true,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: true,
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    fontSize: "11px",
  },
};
export const optionsStatus = {
  chart: {
    type: "bar",
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: "30%",
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: "11px",
    },
  },
  colors: statusColors,
  stroke: {
    width: 2,
    colors: ["#fff"],
  },
  xaxis: {
    categories: [""],
    labels: {
      show: true,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: true,
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    fontSize: "11px",
  },
};

export const historicoPontosPorHora = {
  chart: {
    type: "area",
    toolbar: {
      show: false,
    },
  },
  colors: ["#0C12F2"],
  dataLabels: {
    enabled: true,
    style: {
      fontSize: "11px",
    },
  },
  grid: {
    borderColor: "#e7e7e7",
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  markers: {
    size: 0.2,
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    min: 0,
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: "bottom",
    horizontalAlign: "left",
    fontSize: "11px",
  },
};

export const pontosPorFilial = {
  chart: {
    type: "area",
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    enabled: true,
  },
  colors: paletteColors,
  dataLabels: {
    enabled: true,
    offsetY: -3,
    style: {
      fontSize: "10px",
      style: {
        fontSize: "10px",
        colors: paletteColors,
      },
    },
  },
  grid: {
    borderColor: "#e7e7e7",
    row: {
      colors: ["#f3f3f3", "transparent"],
      opacity: 1,
    },
  },
  markers: {
    size: 0.2,
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    min: 0,
  },
  legend: {
    position: "bottom",
    horizontalAlign: "left",
    fontSize: "11px",
  },
  fill: {
    opacity: 1,
  },
};

export const historicoDesvios = {
  chart: {
    type: "area",
    toolbar: {
      show: false,
    },
  },
  colors: criticalColors,
  dataLabels: {
    enabled: true,
    style: {
      fontSize: "10px",
    },
  },
  grid: {
    borderColor: "#e7e7e7",
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  markers: {
    size: 0.4,
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    min: 0,
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    fontSize: "11px",
  },
  fill: {
    opacity: 1,
  },
};

export const radarDesvios = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      distributed: true,
      barHeight: "10%",
      barWidth: "10%",
    },
  },
  colors: paletteColors,
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: "category",
    labels: {
      style: {
        colors: paletteColors,
      },
    },
  },
  legend: {
    show: false,
  },
};

export const columnsRanking = [
  {
    Header: "Posição",
    accessor: "posicao",
    disableSortBy: true,
    show: true,
  },
  {
    Header: "Área",
    accessor: "area",
    sortType: "basic",
    show: true,
  },
  {
    Header: "Filial",
    accessor: "filial",
    show: true,
    disableSortBy: true,
  },
  {
    Header: "Desvios",
    accessor: "desvios",
    sortType: "basic",
    show: true,
  },
  {
    Header: "Pontos",
    accessor: "pontos",
    sortType: "basic",
    show: true,
  },
  {
    Header: "Responsável",
    accessor: "responsavel",
    sortType: "basic",
    show: true,
  },
];
