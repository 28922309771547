import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  h1 {
    font-size: 16px;
    font-weight: bold;
    color: #494f65;
  }

  p {
    font-size: 14px;
    font-weight: 600;
    color: #95aac9;
  }
`
export const Schedule = styled.div`
  color: ${(props) => props.color};
  font-weight: bold;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 100px;
  border-color: ${(props) => props.color};;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  width: 68px;
  height: 28px;
`


export const Criticidade = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;

  div {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
    background: ${(props) => props.color};
  }

  p {
    color: ${(props) => props.color};
    font-size: 14px;
  }
`
export const Pontos = styled.div`
  color: #F64E60;
  padding: 5px 0px;
  font-weight: 600;
  background-color: #F64E6015;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 12px;

`
export const Position = styled.div`
  font-weight: 600;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 20px;
  font-size: 12px;
  margin-left: 10px;

  ${({ position, total }) => position < 10 && `
    border: 1px solid #00e676;
    color: #00e676;
    background-color: #e8f5e9;
  `}

${({ position, total }) => position > (total - 11) && `
    border: 1px solid #e45;
    color: #e45;
    background-color: #F64E6015;
  `}
  
`

export const PositionBlue = styled.div`
  text-transform: capitalize;
  color: #95AAC9;
  font-weight: 600;
  background-color: #95AAC91A;
  border-radius: 100px;
  border: 1px solid #95AAC9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 20px;
  font-size: 12px;
  margin-left: 10px;
`

export const CenterRows = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`