import * as types from "./types";

export function setFilter(query) {
  return {
    type: types.SET_FILTER,
    payload: { query },
  };
}

export function setFilterSuccess(query) {
  return {
    type: types.SET_FILTER_SUCCESS,
    payload: { query },
  };
}

export function resetDateSuccess() {
  return {
    type: types.RESET_DATE_SUCCESS,
    payload: {},
  };
}

export function resetFilialSuccess() {
  return {
    type: types.RESET_FILIAL_SUCCESS,
    payload: {},
  };
}

export function resetAreaSuccess() {
  return {
    type: types.RESET_AREA_SUCCESS,
    payload: {},
  };
}

export function resetCriticalitySuccess() {
  return {
    type: types.RESET_CRITICALITY_SUCCESS,
    payload: {},
  };
}

export function resetResponsibleSuccess() {
  return {
    type: types.RESET_RESPONSIBLE_SUCCESS,
    payload: {},
  };
}

export function resetInfractionSuccess() {
  return {
    type: types.RESET_INFRACTION_SUCCESS,
    payload: {},
  };
}

export function resetViagemSuccess() {
  return {
    type: types.RESET_VIAGEM_SUCCESS,
    payload: {},
  };
}

export function resetStatusUsuarioSuccess() {
  return {
    type: types.RESET_STATUS_USUARIO_SUCCESS,
    payload: {},
  };
}
