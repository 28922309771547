import React, { useLayoutEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import history from "~/services/history";
import Icon from "~/components/Icons";
import { Avatar, Button, Typography } from "@material-ui/core";
import CustomerServiceIcon from "~/images/customer_service.svg";
import DriverIcon from "~/images/ico-motorista.svg";
import DriverIconColor from "~/images/ico-motorista-color.svg";
import OnisysLogo from "~/images/logo.svg";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    position: "relative",
    transition: "none !important",
  },
  drawerPaper: {
    width: drawerWidth,
    transition: "none !important",
    background: "#2E3240",
    zIndex: 500,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "center",
    transition: "none !important",
  },
  iconButton: {
    position: "absolute",
    right: "-20px",
    height: "24px",
    width: "24px",
    backgroundColor: "#fff",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: "#494F65",
    },
  },
  drawerIcon: {
    color: "#494F65 !important",
    transition: "0.3s",
    "&:hover": {
      color: "#FFF !important",
    },
  },
  itemList: {
    fontSize: "16px",
    color: "#FFFFFF",
    opacity: 0.7,
    transition: "0.3s ease-out",
    backgroundColor: "#2E3240",
    padding: "10px 20px",
    "&:hover": {
      opacity: 1,
    },
    "&:active": {
      opacity: 1,
    },
  },
  icons: {
    opacity: 0.7,
    transition: "0.3s ease-out",
    "&:hover": {
      opacity: 1,
    },
    "&:active": {
      opacity: 1,
    },
  },
  information: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    top: "80vh",
    left: "30px",
  },
  button: {
    display: "flex",
    width: "160px",
    backgroundColor: theme.palette.info.secondary,
    color: "white",
    fontSize: "13px",
    textTransform: "capitalize",
  },
  helpText: {
    fontSize: "12px",
    color: "#fff",
    opacity: 0.5,
    margin: "10px",
  },
}));

const route_map = {
  dashboard: 0,
  desvios: 1,
  disponibilidade: 2,
  dispositivos: 3,
  usuarios: 4,
};

const itemsConfig = {
  dashboard: {
    icon: "grid-outline",
    text: "Dashboard",
    link: `/dashboard`,
  },

  desvios: {
    icon: "alert-triangle-outline",
    text: "Desvios",
    link: `/desvios`,
  },

  disponibilidade: {
    icon: "car-outline",
    text: "Disponibilidade",
    link: `/disponibilidade`,
  },

  dispositivos: {
    icon: "stop-circle-outline",
    text: "Dispositivos",
    link: `/dispositivos`,
  },

  usuarios: {
    icon: "people-outline",
    text: "Usuários",
    link: `/usuarios`,
  },
};

export default function PersistentDrawerLeft({ open, handleDrawerClose }) {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const indexRoute = route_map[history.location.pathname.split("/", 2)[1]];

  const selectColor = (index) =>
    index === indexRoute ? "rgba(189, 204, 48, 1)" : "#fff";

  useLayoutEffect(() => {
    setSelectedIndex(indexRoute);
  }, [indexRoute]);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <img style={{ width: "150px" }} src={OnisysLogo} alt={"Onisys"} />
      </div>
      <Divider />
      <List style={{ padding: "0px" }}>
        {[
          "dashboard",
          "desvios",
          "disponibilidade",
          "dispositivos",
          "usuarios",
        ].map((item, index) => {
          const iconName = itemsConfig[item].icon;

          return (
            <ListItem
              className={classes.itemList}
              onClick={() => history.push(itemsConfig[item].link)}
              disableTouchRipple
              selected={index === selectedIndex}
              button
              key={item}
              style={
                selectedIndex === index
                  ? {
                      backgroundColor: "#494F65",
                      borderRight: "5px solid #BDCC30",
                      opacity: 1,
                      borderRadius: "none",
                    }
                  : {}
              }
            >
              <ListItemIcon>
                {iconName !== "stop-circle-outline" ? (
                  <Icon
                    className={classes.icons}
                    name={iconName}
                    color={selectColor(index)}
                  />
                ) : selectedIndex === index ? (
                  <img alt={DriverIconColor} src={DriverIconColor} />
                ) : (
                  <img alt={DriverIcon} src={DriverIcon} />
                )}
              </ListItemIcon>
              <ListItemText
                style={{ marginLeft: "-17px" }}
                primary={itemsConfig[item].text}
              />
            </ListItem>
          );
        })}
      </List>
      <div className={classes.information}>
        <Typography className={classes.helpText}>Precisa de Ajuda?</Typography>
        <Link
          to={{ pathname: "https://app.pipefy.com/public/form/JZacJNxn" }}
          target="_blank"
        >
          <Button
            color={theme.palette.secondary.main}
            className={classes.button}
            startIcon={
              <Avatar
                style={{ height: "31px", width: "31px" }}
                src={CustomerServiceIcon}
              />
            }
          >
            Abrir Chamado
          </Button>
        </Link>
      </div>
    </Drawer>
  );
}
