import { Select } from "@material-ui/core";
import styled from "styled-components";

export const StyledSelect = styled(Select)`
  min-width: 50px !important;
  width: 100%;
`;

export const StyledButton = styled.button`
  position: absolute;
  top: 16px;
  right: 37px;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  transition: all .2s ease-in-out;
  :hover{
    transform: scale(1.1);
  }
`;