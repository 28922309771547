import React from 'react'
import * as S from './styled'
import Icon from '~/components/Icons'
import Select from '~/components/Inputs/InputSelect'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { useTable, useSortBy, usePagination } from 'react-table'
import CheckFiltersCard from '../Cards/CheckFiltersCard'

const TableRanking = ({
  handleFilter,
  columns,
  data,
  title,
  search,
  exportTo,
  headerEnd,
  onClickRow,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 20 },
    },
    useSortBy,
    usePagination
  )

  return (
    <S.Wrapper {...props}>
      <S.Header>
        {search && (
          <S.HeaderCol>
            <S.Search>
              <input
                onChange={handleFilter}
                style={{ width: '180px', backgroundColor: '#F9FBFD' }}
                placeholder={search}
              />
              <Icon name='search' size={14} />
            </S.Search>
          </S.HeaderCol>
        )}
        <S.HeaderCol>
          <S.HeaderCol>{headerEnd}</S.HeaderCol>
        </S.HeaderCol>
      </S.Header>
      {data && data?.length > 0 ? (
        <>
          <S.Table {...getTableProps()}>
            <S.Head>
              {headerGroups.map(headerGroup => (
                <S.Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <S.Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span style={{ position: 'relative' }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <div
                              style={{
                                position: 'absolute',
                                top: 0,
                                right: -20,
                              }}
                            >
                              <ArrowDropUpIcon fontSize='small' />
                            </div>
                          ) : (
                            <div
                              style={{
                                position: 'absolute',
                                top: 0,
                                right: -20,
                              }}
                            >
                              <ArrowDropDownIcon fontSize='small' />
                            </div>
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </S.Th>
                  ))}
                </S.Tr>
              ))}
            </S.Head>
            <S.Body {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <S.Tr
                    {...row.getRowProps(
                      onClickRow && {
                        onClick: () => onClickRow(row),
                      }
                    )}
                  >
                    {row.cells.map(cell => {
                      return (
                        <S.Td {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </S.Td>
                      )
                    })}
                  </S.Tr>
                )
              })}
            </S.Body>
          </S.Table>
          <S.Footer>
            <S.FooterCol>
              <p>Exibindo</p>
              <Select
                value={pageSize}
                variant='outlined'
                margin='dense'
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                items={[
                  { name: '10', value: 10 },
                  { name: '20', value: 20 },
                  { name: '30', value: 30 },
                ]}
                style={{
                  minWidth: 80,
                }}
              />
              <p>{`de ${data.length}`}</p>
            </S.FooterCol>
            {data.length > pageSize && (
              <S.FooterCol>
                <S.Control onClick={() => gotoPage(0)}>
                  <Icon name='chevron-left-outline' />
                  <p>Primeiro</p>
                </S.Control>
                <S.Control onClick={() => previousPage()}>
                  <Icon name='chevron-left-outline' />
                  <p>Anterior</p>
                </S.Control>
                <S.Control onClick={() => nextPage()}>
                  <p>Próximo</p>
                  <Icon name='chevron-right-outline' />
                </S.Control>
                <S.Control onClick={() => gotoPage(pageCount - 1)}>
                  <p>Último</p>
                  <Icon name='chevron-right-outline' />
                </S.Control>
              </S.FooterCol>
            )}
          </S.Footer>
        </>
      ) : (
        <CheckFiltersCard />
      )}
    </S.Wrapper>
  )
}

export default TableRanking
