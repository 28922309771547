import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Icon from "~/components/Icons";
import * as S from "./styled";

import { Button, useTheme } from "@material-ui/core";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "300px",
    height: "200px",
    backgroundColor: "#FFFFFF",
    border: "0px 5px 20px #B0C3D329;",
    borderRadius: "4px",
    padding: "25px 25px",
    margin: "0 auto",
  },
  root: {
    flexGrow: 1,
  },
}));

export const SaveModal = ({ handleClose, open, title, subtitle, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <S.Header>
        <S.Title>Salvar {title}</S.Title>
        <S.RightHeader>
          <S.CloseButton
            startIcon={<Icon name="close" color="#223345" />}
            onClick={handleClose}
            variant="inherit"
            style={{ backgroundColor: "transparent" }}
          />
        </S.RightHeader>
      </S.Header>
      <S.Main className={classes.root}>
        <S.Paragraph>Deseja salvar as alterações {subtitle}?</S.Paragraph>
      </S.Main>
      <S.Footer>
        <S.RightFooter>
          <Button
            size="small"
            variant="contained"
            style={{
              backgroundColor: "#DDE5F0",
              color: theme.palette.info.main,
            }}
            onClick={handleClose}
          >
            Não
          </Button>
          <Button
            onClick={onClick}
            size="small"
            type="submit"
            color="primary"
            variant="contained"
            style={{
              color: "#fff",
              marginLeft: "10px",
            }}
          >
            Sim
          </Button>
        </S.RightFooter>
      </S.Footer>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};
