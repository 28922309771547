import { useEffect, useState } from "react";
import api from "~/services/api";

const useRequestData = (endpoint) => {
  const [data, setData] = useState(null);
  
  useEffect(() => {
    const requestData = async () => {
      try {
        const response = await api.get(`${endpoint}`);
        setData(response.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    requestData();
  }, [endpoint]);

  return data;
};

export default useRequestData;
