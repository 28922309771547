import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as S from './styled'
import Icon from '~/components/Icons'
import { Grid, Modal, Card } from '@material-ui/core';

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function ImageModal({ image, open, handleClose }) {
  
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
        <S.Main className={classes.root}>
            <Grid container spacing={2}>             
              <Grid item xs={12} md={12}>
                <Card elevation={0} className={classes.card} style={{position: 'relative'}}>
                    <img
                      className={classes.media}
                      src={image}
                      alt={image}
                    />
                    <S.IconDiv onClick={handleClose}>        
                      <Icon
                        className={classes.icon}                
                        name={'close'} 
                        size={25} 
                        color={'#BDCC30'} 
                      />
                    </S.IconDiv>                    
                </Card>               
              </Grid>
            </Grid>
        </S.Main>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: 'transparent',    
  },
  root: {
    flexGrow: 1,
    padding: '0px'
  },
  media: {
    minHeight: '75vh',
    maxHeight: '75vh',
    borderRadius: '4px',
  },
  card: {
    backgroundColor: 'transparent',    
  },
  icon: {
    cursor: 'pointer',
    margin: '7px',
    zIndex: 999,
    transform: 'scale(1)',
    transition: '0.3s ease-out',
    '&:hover': {
      transform: 'scale(1.3)',
    },
  },
}));

