import styled from 'styled-components'

export const StyledButton = styled.button`
    border: none;
    outline: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 7px 0px 7px 0px;
    margin-left: 5px;
`;