import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Icon from '~/components/Icons'
import * as S from './styled'
import {useDispatch} from 'react-redux'
import { signOut } from '~/store/modules/auth/actions'
import { List, ListItemIcon, Typography } from '@material-ui/core';
 
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 180,
        right: '50px',
        top: '60px',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1.5),
        boxShadow: '0px 3px 10px #95AAC91A !important',
        borderRadius: '4px',
        margin: '-10px 0px -10px 0px',
        [theme.breakpoints.down("xs")]: {
            width: '80%'
        },
    },
    listItemIcon: {
        minWidth: '30px',
    }
}));

export default function ModalUserConfig({ open, handleClose }) {

    const dispatch = useDispatch()

    const logout = () => {
        dispatch(signOut())
    }
 
  const classes = useStyles();
  const body = (
    <List className={classes.paper}>
        <S.StyledButton onClick={logout}>
            <ListItemIcon className={classes.listItemIcon}>                      
                <Icon name="log-out" color='#494F65' size={20}/>
            </ListItemIcon>
            <Typography style={{fontSize: '10px! important', color: '#494F65'}}>Sair</Typography>
        </S.StyledButton>
    </List>
  );

  return (
    <div>
      <Modal 
        open={open} 
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
    >
        {body}
      </Modal>
    </div>
  );
}
