import styled from 'styled-components'

export const StyledButton = styled.button`
  display: flex; 
  align-items:center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
`

export const Row = styled.div`
  display: flex; 
  align-items:center;
  justify-content: space-between;
  margin-top: 15px;

`

export const Container = styled.div`
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  justify-content:space-between;

`