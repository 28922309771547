import React from "react";
import * as S from "./styled";
import Icon from "~/components/Icons";
import useColor from "~/utils/hooks/useColor";

function Header({ desvio }) {
  return (
    <S.Header>
      <div style={{ display: "flex", marginBottom: "15px" }}>
        <S.Title>Desvios</S.Title>
        <div style={{ marginTop: "-6px" }}>
          <Icon name="chevron-right-outline" size={22} color={"#494F65"} />
        </div>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <S.SubTitle>{`Revisão da Ocorrência #${
            desvio ? desvio.id : ""
          }`}</S.SubTitle>
          <S.Criticidade
            style={{ marginLeft: 20 }}
            color={useColor(desvio && desvio.status)}
          >
            <div />
            <p>{desvio ? desvio.status.toLowerCase() : ""}</p>
          </S.Criticidade>
        </div>
      </div>
    </S.Header>
  );
}

export default Header;
