import React, { useState, useEffect } from "react";
import * as S from "./styled";
import Table from "~/components/Table";
import Filter from "./Filtros";
import Icon from "~/components/Icons";
import { setFilter } from "../../store/modules/filter/actions";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import Tabs from "~/components/Tabs";
import Loading from "~/components/Loading"
import { useHistory } from "react-router-dom";
import {
  requestDesviosList,
  requestTravels
} from './services'
import { toast } from "react-toastify";


const howToColor = (name) => {
  if (name.toLowerCase().trim() === "baixa") {
    return "#3699FF";
  } else if (name.toLowerCase().trim() === "média") {
    return "#FFA801";
  } else {
    return "#F64E60";
  }
};

const formatDate = (date) => {
  return format(new Date(date), "dd/MM/yyyy");
};

const Desvios = () => {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.filter);
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const [filteredData, setFilteredData] = useState(null);
  const [viagem, setViagem] = useState(null);
  const [viagens, setViagens] = useState(null);
  const [deviationData, setDeviationData] = useState(null);

  const tabsString = ["ABERTO", "PENDENTE", "FINALIZADO", "DELETADO"];

  const requestDeviation = async (data) => {
    setDeviationData(null)
    const response = await requestDesviosList(data)
    if (response.data) setDeviationData(response.data);
    else toast.error("Erro ao carregar desvios!")
  };

  const requestTravel = async () => {
    const travels = await requestTravels()
    setViagens(travels.data)
  }

  const handleTab = (e, tabValue) => {
    setTabValue(tabValue);
    tabsFilter(tabsString[tabValue]);
  };

  useEffect(() => {
    requestTravel()
  }, []);

  useEffect(() => {
    requestDeviation(query);
  }, [query]);

  useEffect(() => {
    setFilter(deviationData);
    handleTab("o", tabValue);
  }, [deviationData]);

  useEffect(() => {
    tabViagemFilter()
  }, [tabValue, viagem]);

  //@FILTERS
  const tabsFilter = (status) => {
    let filtered = deviationData;
    if (filtered && filtered !== null) {
      const newData = filtered.filter((item) => item.status === status);
      setFilteredData(newData);
    }
  };

  const tabViagemFilter = () => {
    if (deviationData && deviationData !== null) {
      const filteredTab = deviationData.filter((item) => item.status === tabsString[tabValue]);
      if (viagem) {
        const filterTabViagem = filteredTab.filter((item) => item.viagem === viagem);
        setFilteredData(filterTabViagem);
      }
      else setFilteredData(filteredTab);
    }
  };

  const handleOnChange = (value, name) => {
    if (name === "filial") {
      dispatch(
        setFilter({
          ...query,
          filial: value,
        })
      );
    } else if (name === "area") {
      dispatch(
        setFilter({
          ...query,
          area: value,
        })
      );
    } else if (name === "date") {
      dispatch(
        setFilter({
          ...query,
          date: value,
        })
      );
    } else if (name === "viagem") {
      setViagem(value);
      dispatch(
        setFilter({
          ...query,
          viagem: value,
        })
      );
    } else if (name === "infracao") {
      dispatch(
        setFilter({
          ...query,
          tipo: value,
        })
      );
    } else if (name === "responsavel") {
      dispatch(
        setFilter({
          ...query,
          responsavel: value,
        })
      );
    }
  };

  const columns = [
    {
      Header: "Número",
      accessor: "id",
      sortType: "basic",
      Cell: ({ row }) => <h4>{row.original.id ? row.original.id : ""}</h4>,
    },
    {
      Header: "Viagem",
      accessor: "viagem",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div>{row.original.viagem ? row.original.viagem : ""}</div>
      ),
    },
    {
      Header: "Area",
      accessor: "area",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div>{row.original.area ? row.original.area.nome : ""}</div>
      ),
    },
    {
      Header: "Desvio",
      accessor: "desvio",
      sortType: "basic",
      Cell: ({ row }) => (
        <div>{row.original.tipo ? row.original.tipo.descricao : ""}</div>
      ),
    },
    {
      Header: "Data",
      accessor: "date",
      disableSortBy: true,
      Cell: ({ row }) => {
        const date_desvio =
          row.original.data_desvio !== null
            ? formatDate(row.original.data_desvio)
            : "";
        return <div style={{ fontSize: "14px" }}>{date_desvio}</div>;
      },
    },
    {
      Header: "Reportado em",
      accessor: "reported",
      disableSortBy: true,
      Cell: ({ row }) => {
        const created_at =
          row.original.createdAt !== null
            ? formatDate(row.original.createdAt)
            : "";
        return <div style={{ fontSize: "14px" }}>{created_at}</div>;
      },
    },
    {
      Header: "Criticidade",
      accessor: "criticidade",
      disableSortBy: true,
      Cell: ({ row }) => (
        <S.Criticidade
          color={howToColor(
            row.original.tipo ? row.original.tipo.criticidade : ""
          )}
        >
          <div />
          <p>
            {row.original.tipo
              ? row.original.tipo.criticidade.toLowerCase()
              : ""}
          </p>
        </S.Criticidade>
      ),
    },
    {
      Header: "Ações",
      accessor: "acoes",
      disableSortBy: true,
      Cell: ({ row }) => (
        <S.Action onClick={() => history.push(`/desvios/${row.original.id}`)}>
          <Icon name="edit-outline" />
        </S.Action>
      ),
    },
  ];

  return (
    <>
      <Filter
        handleOnChange={handleOnChange}
        viagens={viagens}
        viagem={query.viagem}
        responsible={query.responsavel}
        infraction={query.tipo}
        filial={query.filial}
        area={query.area}
        title={"Desvios"}
      />

      {deviationData ? (
        <S.Container>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTab}
            items={["ABERTOS", "PENDENTES", "FINALIZADOS", "DELETADOS"]}
          />
          <Table
            noPagging
            style={{ marginTop: 22 }}
            columns={columns}
            data={filteredData ? filteredData : deviationData}
            onClickRow={(row) => history.push(`/desvios/${row.original.id}`)}
          />
        </S.Container>
      ) :
        <Loading />
      }
    </>
  );
};

export default Desvios;
