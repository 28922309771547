import styled from 'styled-components'

export const Circle = styled.button`
    border: none;
    padding: 3px;
    background-color: ${props => props.alert ? '#FF9931' : props.danger ? '#F64E60' : props.info ? '#3699FF' : '#1BC5BD'};
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin: 0px 5px 0px 15px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10%;
    margin-bottom: 5px;
    margin-top: -15px;
`;

export const IconDiv = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
`

export const Title = styled.h1`
    display: flex;
    align-items: center;
    font-size: 22px;
    color: '#223345';
    font-weight: 900;
    letter-spacing: 0px;
`;

export const Span = styled.span`
    color: ${props => props.alert ? '#FF9931' : props.danger ? '#F64E60' : props.info ? '#3699FF' : '#1BC5BD'};
    font-size: 13px;
    font-weight: 900;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
`;

export const Main = styled.div`
    display: flex;

`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 8%;
    background: '#F9FBFD';
`;

export const RightFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;