import styled from 'styled-components'

export const Item = styled.div`
  svg{
    font-size: 20px;
  }

`
export const Title = styled.h1`
  color: #494F65;
  font-weight: 900;
`
export const Button = styled.div`
  background: #494F65;
  color: #fff;
  height: 24px;
  width: 24px;
  z-index: 999999;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;

  @media (max-width: 820px) {

  }



  ${({ open }) => open && `
    position: relative;
    left: -36px;
    background: #fff;
    color: #494F65;
  `}

  
`;

export const UserContainer = styled.div`
  display: flex; 
  align-items:flex-end; 
  justify-content:center;
  flex-direction: column;
  margin-right: 7px;

  h2 {
    color: #2E3240;
    font-size: 14px;
    margin-bottom: -2px;
  }

  p {
    color: #95AAC9;
    font-size: 10px;
  }
`
export const DateContainer = styled.div`
  height: 54px; 
  margin:8px;
  border-radius: 4px; 
  width: 100%;
  display:flex; 
  align-items: center;
  justify-content:space-between;
  border: 1px solid #95AAC9;
  padding: 0px 12px;
  span{
    font-size: 14px;
    color: #95AAC9;
    letter-spacing: -0.05px;
    font-family: 'Roboto';
    font-weight: 400;
  }
`
export const Row = styled.div`
  display: flex; 
  align-items:center;

`
export const StyledButton = styled.button`
  display: flex; 
  align-items:center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
`