import api from '~/services/api'
import { auth } from "~/utils/firebase"

const requestDesviosList = async (query) => {
  const res = await api.get('/infractions', { params: query })
  return res
}

const requestTravels = async () => {
  const res = await api.get('/travels')
  return res
}

const deleteDesvio = async (desvio) => {
  const query = { motivo_exclusao: desvio.motivo_exclusao }
  const res = await api.delete(`/infractions/${desvio.id}`, { params: query })
  return res
}

const updateDesvio = async (desvio) => {
  const res = await api.put(`/infractions/${desvio.id}`, desvio)
  return res
}

const requestDesvio = async (id) => {
  const res = await api.get(`/infraction/${id}`)
  return res
}

const getUserInfo = async() => {
  const userInfo = await api.get(`/users/${auth.currentUser.uid}`);
  return userInfo
}

export {
  requestDesviosList,
  requestTravels,
  deleteDesvio,
  updateDesvio,
  requestDesvio,
  getUserInfo }