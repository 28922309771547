import React, { useState, useEffect } from "react";
import * as S from "./styled";
import Filter from "./Filtros";
import Loading from "~/components/Loading";
import TableDisponibility from "~/components/TableDisponibility";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "~/store/modules/filter/actions";
import { requestAvailable } from "./services"
import { toast } from "react-toastify";

const Disponibilidade = () => {
  const dispatch = useDispatch();

  const [tableRows, setTableRows] = useState([]);
  const [filterDisponibility, setFilterDisponibility] = useState(null);
  const [days, setDays] = useState(null);
  const query = useSelector((state) => state.filter);

  const requestDisponibility = async (data) => {
    const response = await requestAvailable(data)
    if (response.data && response.data.rows) {
      setTableRows(response.data.rows)
      setDays(response.data.daysArray)
    }
    else if (response.data.error) {
      toast.error(response.data.error)
    }
  };

  useEffect(() => {
    const filters = {
      mes: query.mesFinal,
      ano: query.anoFinal,
      filial: query.filial,
      area: query.area
    }
    requestDisponibility(filters);
  }, [query]);

  //FILTER
  const handleOnChange = (value, name) => {
    if (value) {
      if (name === "filial") {
        dispatch(
          setFilter({
            ...query,
            filial: value,
          })
        );
      } else if (name === "area") {
        dispatch(
          setFilter({
            ...query,
            area: value,
          })
        );
      }
    }
  };

  const onFilteredChangeDisponibility = (e) => {
    let filtered = tableRows;
    const { value } = e.target;

    if (value.length > 0) {
      const newData = filtered.filter((r) => {
        return r.nome
          .trim()
          .toLowerCase()
          .includes(value.trim().toLowerCase());
      });
      setFilterDisponibility(newData);
    } else {
      setFilterDisponibility(tableRows);
    }
  };

  const disponbilityColumns = [
    {
      Header: "Dispositivo",
      columns: [
        {
          Header: "",
          accessor: "dispositivo",
          sortType: "basic",
          Cell: ({ row }) => <div style={{ width: '150px' }}>{row.original.nome}</div>,
        },
      ],
    },
    {
      Header: "Dia do mês",
      columns: [
        {
          Header: (
            <S.MonthDay>
              {days ? days.map(item => <div>{item}</div>) : <div style={{ width: '700px' }} />}
            </S.MonthDay>
          ),
          accessor: "status",
          sortType: "basic",
          Cell: ({ row }) => (
            <S.Status>
              {row.original.disponibilidade.map((item) => (item !== null ? (
                <S.StatusWrapper style={{ position: "relative" }}>
                  <S.UnChecked
                    color={item ? item.status : null}
                  />
                  <S.Label>
                    {item.horas && item.horas.map(h => <div style={{marginLeft: '20px'}}>{h}</div>)}
                  </S.Label>
                </S.StatusWrapper>
              ) : (
                <S.StatusWrapper style={{ position: "relative" }}>
                  <S.UnChecked />
                  <S.Label>{item ? item.horas : null}</S.Label>
                </S.StatusWrapper>
              )
              ))}
            </S.Status>
          ),
        },
      ],
    },
    {
      Header: "% rel",
      columns: [
        {
          Header: "",
          accessor: "rel_percentage",
          sortType: "basic",
          Cell: ({ row }) => <div style={{ width: '60px' }}>{row.original.perc_rel && (row.original.perc_rel * 100).toFixed(2)}</div>,
        },
      ],
    },
    {
      Header: "% abs",
      columns: [
        {
          Header: "",
          accessor: "abs_percentage",
          sortType: "basic",
          Cell: ({ row }) => <div style={{ width: '60px' }} >{row.original.perc_abs && (row.original.perc_abs * 100).toFixed(2)}</div>,
        },
      ],
    },
  ];

  return (
    <>
      <Filter
        title={"Disponibilidade"}
        handleOnChange={handleOnChange}
        filial={query.filial}
        area={query.area}
      />
      {tableRows ? <S.Container>
        <TableDisponibility
          style={{ marginTop: 15 }}
          handleFilter={onFilteredChangeDisponibility}
          columns={disponbilityColumns}
          data={filterDisponibility ? filterDisponibility : tableRows}
          search="Pesquisar por Dispositivo"
        />
      </S.Container> : <Loading />}
    </>
  );
};

export default Disponibilidade;
