import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  padding: 0px 0px 0px 5px;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: "#223345";
  letter-spacing: 0px;
  text-transform: uppercase;
`;

export const Paragraph = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: gray;
  letter-spacing: 0px;
  margin-bottom: 10px;
`;

export const Main = styled.div`
  display: flex;
  height: 70%;
  width: 100%;
  text-align: center;
`;

export const RightHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  display: flex;
  width: 550px;
  align-items: center;
  justify-content: flex-end;
  background: "#F9FBFD";
  position: absolute;
  bottom: 23px;
`;

export const RightFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled(Button)`
  min-width: 15px !important;
  max-width: 15px !important;
  padding: 0px !important;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  :hover {
    transform: scale(1.1);
  }

  .MuiButton-startIcon {
    padding: 0px !important;
    margin: 0px !important;
  }
`;
