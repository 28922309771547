import { useState, useEffect } from 'react'

function useColor(name, background) {
    const [color, setColor] = useState('')
    
    useEffect(() => {
        if((name === 'BAIXA') || (name === 'FINALIZADO') || (name === 'AGUARDANDO')){
            setColor('#3699FF')
        } else if ((name === "MÉDIA") || (name === 'ABERTO') || (name === "ABERTO")){
            setColor('#FFA801')
        }  else {
            setColor('#F64E60')
        }
    }, [name])    

    return color
}

export default useColor