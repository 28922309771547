import api from '../../services/api'

const requestUsers = async () => {
  const res = await api.get('/users')
  return res
}

const requestFilials = async() => {
  const res = await api.get('/filials')
  return res
}

export { requestUsers,  requestFilials}