import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 22px 0;

  div {
    display: flex;
  }

  h1 {
    font-size: 32px;
    color: #494f65;
    font-weight: 900;
  }
`;

export const Item = styled.p`
  text-transform: capitalize;
  font-size: 17px;
`;

export const Main = styled.div``;

export const Footer = styled.div`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    button {
      margin-left: 25px;
    }
  }
`;

export const Card = styled(Paper)`
  padding: 20px;
  height: 546px;
  background: #ffffff;
  box-shadow: 0px 5px 20px #b0c3d329;
  border-radius: 5px;
  opacity: 1;
`;

export const CardMain = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 0px;
`;

export const Criticidade = styled.div`
  display: flex;
  align-items: center;

  div {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
    background: ${(props) => props.color};
  }

  p {
    color: ${(props) => props.color};
    font-size: 16px;
  }
`;
