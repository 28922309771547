import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 4px;
`;

export const Header = styled.div`
  padding: ${({ noPagging }) => (noPagging ? "0px" : "22px")};
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    color: #494f65;
    font-size: 14px;
    margin-right: 30px;
  }

  h2 {
    color: #95aac9;
    font-size: 10px;
    text-transform: uppercase;
    margin-right: 30px;
  }
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: 0px;
`;

export const Head = styled.thead`
  width: 100%;
  background: #edf2f9;
  color: #95aac9;
  text-transform: uppercase;
`;

export const Body = styled.tbody`
  color: #494f65;
`;

export const Tr = styled.tr`
  height: 50px;
  margin: 0;
`;

export const Th = styled.th`
  padding: 10px 22px !important;
  &:nth-child(6) {
    text-align: ${(props) => props.center};
  }
  &:nth-child(7) {
    text-align: ${(props) => props.center};
  }
`;

export const Td = styled.td`
  padding: 10px 22px;
  border-bottom: 1px solid #b0c3d329;
`;

export const Row = styled.div`
  display: flex;
  height: 71.5px;
`;

export const Search = styled.div`
  width: 344px;
  height: 40px;
  border: 1px solid #edf2f9;
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9fbfd;

  input {
    border: none;
    color: #2e3240;
    ::placeholder {
      color: #95aac9;
      background-color: "#F000";
    }
    background-color: "#F9FBFD";
    width: 200px;
  }
`;

export const HeaderCol = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Export = styled.div`
  width: 113px;
  height: 40px;
  color: #95aac9;
  border: 1px solid #95aac9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const Footer = styled.div`
  color: #7d9eb5;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 22px;
  align-items: center;
  height: 100px;

  select {
    margin: 0 25px;
  }
`;

export const FooterCol = styled.div`
  display: flex;
  align-items: center;
`;

export const Control = styled.button`
  margin-left: 15px;
  display: flex;
  flex-direction: justify-content;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
`;

export const InfoIcon = styled.button`
  display: none;
  outline: none;
  border: none;
  transition: 0.9s ease-in-out;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  transition: 0.9s ease-in-out;
  &:hover ${InfoIcon} {
    display: flex;
  }
`;
