import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  background: #fff;
`

export const Header = styled.div`
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    color: #494f65;
    font-size: 14px;
    margin-right: 30px;
  }

  h2 {
    color: #95aac9;
    font-size: 10px;
    text-transform: uppercase;
    margin-right: 30px;
  }
`

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: 0px;
`

export const Head = styled.thead`
  width: 100%;
  background: #edf2f9;
  color: #95aac9;
  text-transform: uppercase;
`

export const Body = styled.tbody`
  color: #494f65;
`

export const Tr = styled.tr`
  min-height: 27px;
  max-height: 30px;
  margin: 0;
`

export const Plate = styled.div`
  margin-top: 17px; 
  margin-bottom: 10px;
`
export const Percentage = styled.div`
  display: flex;
  justify-content: center;
  color: #2e3240;
  font-weight: 700;
  font-size: 12px;
  margin-left: -20px;
`
export const Status = styled.div`
  display: flex;
  justify-content: space-between;
`
export const NumbersHeader = styled.div`
  width: 22px;
  height: 22px;
  color: '#656E8C';
  text-align: center;
`

export const Th = styled.th`
  padding: 10px 22px;
  &:last-child {
    padding: 10px 22px 10px 0px !important;
    text-align: end;
    width: 100px;
  }
  &:first-child {
    padding: 10px 0px 10px 22px !important;
    width: 50px;
  }
  &:nth-child(2) {
    padding: 10px 0px 10px 15px !important;
  }

`

export const Td = styled.td`
  text-align: left;
  color: '#656E8C';
  padding: 2px 0px 2px 20px;
  &:last-child {
    padding: 2px 0px 2px 0px !important;
  }
  &:first-child {
    width: 50px;
  }
`

export const Search = styled.div`
  width: 344px;
  height: 40px;
  border: 1px solid #EDF2F9;
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F9FBFD;

  input{
    border: none;
    color: #2E3240;   
    ::placeholder {
      color: #95AAC9;
      background-color: '#F000'
    }
  }
`

export const HeaderCol = styled.div`
  display: flex;
  align-items: flex-end;
`

export const Export = styled.div`
  width: 113px;
  height: 40px;
  color: #95aac9;
  border: 1px solid #95aac9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`

export const Footer = styled.div`
  color: #7d9eb5;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 22px;
  align-items: center;
  height: 100px;

  select {
    margin: 0 25px;
  }
`

export const FooterCol = styled.div`
  display: flex;
  align-items: center;
`

export const Control = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: justify-content;
  align-items: center;
  cursor: pointer;
`
