import React, { useState } from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  makeStyles,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import * as S from "./styled";
import Icon from "~/components/Icons";
import Modal from "./ModalUserConfig";
import IconUser from "~/images/ico-user.svg";
const drawerWidth = 240;

const Header = ({ userFunction, userName, open, handleDrawerOpen }) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  return (
    <AppBar
      position="fixed"
      color="inherit"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <S.Button onClick={handleDrawerOpen} open={open}>
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </S.Button>
        <Grid item className={classes.lastSelect} xs={3}>
          <S.UserContainer>
            <h2>{userName && userName}</h2>
            <p>{userFunction && userFunction}</p>
          </S.UserContainer>
          <IconButton
            disableRipple
            className={classes.buttonHover}
            onClick={() => setOpenModal(true)}
          >
            <img src={IconUser} alt={IconUser} />
            <IconButton
              disableRipple
              className={clsx(classes.expand, classes.buttonHover, {
                [classes.expandOpen]: openModal,
              })}
              onClick={() => setOpenModal(true)}
              aria-expanded={openModal}
            >
              <Icon name="arrow-down" color="#494F65" />
            </IconButton>
          </IconButton>
        </Grid>
      </Toolbar>
      <Modal open={openModal} handleClose={() => setOpenModal(false)} />
    </AppBar>
  );
};
export default Header;
const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px 5px 0px 10px",
      height: 80,
    },
  },
  toolbar: {
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      height: 80,
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  hide: {
    position: "absolute",
    left: 0,
  },
  iconButton: {
    height: "24px",
    width: "24px",
    backgroundColor: theme.palette.info.main,
    transition: "0.3s ease-out",
    "&:hover": {
      backgroundColor: theme.palette.gray,
    },
  },
  lastSelect: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  buttonHover: {
    "&:hover": {
      backgroundColor: "#fff !important",
    },
    padding: "5px !important",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));
