import { auth } from "../utils/firebase";
import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || "http://localhost:3333",
});

api.interceptors.request.use(async config => {
    const token = await auth.currentUser.getIdToken()
    if (token) {
        config.headers.Authorization = "Bearer " + token
    }
    return config
}, error => {
    return Promise.reject(error)
}
)

export default api