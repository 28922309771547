import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 20px;
`

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  h1 {
    font-size: 16px;
    font-weight: bold;
    color: #494f65;
  }

  p {
    font-size: 12px;
    font-weight: 600;
    color: #95aac9;
  }
`

export const Driver = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 16px;
    font-weight: 900;
    color: #494f65;
  }

  p {
    font-size: 12px;
    font-weight: 600;
    color: #95aac9;
  }
`

export const Criticidade = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;

  div {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
    background: ${(props) => props.color};
  }

  p {
    color: ${(props) => props.color};
    font-size: 14px;
  }
`
export const Action = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  margin-left: 10px;
`