import React from "react";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import * as S from "./styled";
import TextField from "@material-ui/core/TextField";
import pt from "date-fns/locale/pt-BR";


const minDate = new Date(2020, 6, 1)
const formatedToday = new Date()

export default function InputDate({
  value,
  datePicker,
  dense,
  openTo,
  variant,
  onChange,
  secondStyle,
  header,
  label,
  closeSelect,
  required,
  future,
  ...props
}) {
  if (future) formatedToday.setMonth(formatedToday.getMonth() + 4)
  return (
    <S.Date style={{ width: "100%" }}>
      <LocalizationProvider
        locale={pt}
        dateAdapter={DateFnsUtils}
        style={{ cursor: "pointer", width: "100%" }}
      >
        <DatePicker
          required={required && true}
          variant={variant}
          minDate={minDate}
          maxDate={formatedToday}
          style={{ width: "100%" }}
          openTo={openTo}
          views={datePicker ? ["year", "month", "date"] : ["year", "month"]}
          label={
            <span
              style={{
                color: "#95AAC9 ",
                backgroundColor: "#fff",
                fontFamily: "Roboto",
              }}
            >
              {label}
            </span>
          }
          value={value}
          onChange={onChange}
          renderInput={(props) => (
            <TextField
              margin={dense}
              label={label}
              {...props}
              variant="outlined"
              style={{ width: "100%" }}
            />
          )}
        />
      </LocalizationProvider>
    </S.Date>
  );
}
