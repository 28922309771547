import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import Login from "~/pages/Login";
import Dashboard from "~/pages/Dashboard";
import Desvios from "~/pages/Desvios";
import DesvioDetail from "~/pages/Desvios/DesvioDetalhes";
import Usuarios from "~/pages/Usuarios";
import Dispositivos from "~/pages/Dispositivos";
import Disponibilidade from "~/pages/Disponibilidade";

const Routes = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/desvios" exact component={Desvios} isPrivate />
    <Route path="/desvios/:id" exact component={DesvioDetail} isPrivate />
    <Route path="/usuarios" component={Usuarios} isPrivate />
    <Route path="/dispositivos" component={Dispositivos} isPrivate />
    <Route path="/disponibilidade" component={Disponibilidade} isPrivate />
  </Switch>
);

export default Routes;
