import React, { useState, useEffect } from "react";
import * as S from "./styled";
import Table from "~/components/Table";
import Filter from "./Filtros";
import Icon from "~/components/Icons";
import { useDispatch } from "react-redux";
import Modal from "./EditarUsuario";
import { useSelector } from "react-redux";
import api from "../../services/api";
import { setFilter } from "../../store/modules/filter/actions";

const Users = () => {
  const dispatch = useDispatch();

  const [usersData, setUsersData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [newUser, setNewUser] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const query = useSelector((state) => state.filter);

  const requestUsers = async (data) => {
    const response = await api.get("/users", { params: data });
    setUsersData(response.data.rows);
    setFilterData(null);
  };

  useEffect(() => {
    const filters = {
      status: query.status_usuario,
      filial: query.filial
    }
    requestUsers(filters);
  }, [query]);

  const columns = [
    {
      Header: "Nome",
      accessor: "nome",
      sortType: "basic",
      Cell: ({ row }) => (
        <S.Name>
          <h1>{row.original.nome && row.original.nome.toLowerCase()}</h1>
        </S.Name>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      sortType: "basic",
      Cell: ({ row }) => <div>{row.original.email}</div>,
    },
    {
      Header: "Filial",
      accessor: "filial",
      disableSortBy: true,
      Cell: ({ row }) => <div>{row.original.filial.nome}</div>,
    },
    {
      Header: "Matrícula",
      accessor: "matricula",
      sortType: "basic",
      sortMethod: (a, b) => (a > b ? 1 : -1),
    },
    {
      Header: "Ações",
      accessor: "acoes",
      disableSortBy: true,
      Cell: ({ row }) => (
        <S.IconContainer onClick={() => handleEditModal(row.original)}>
          <Icon name="edit-outline" />
        </S.IconContainer>
      ),
    },
  ];

  const handleEditModal = (row) => {
    setUserInfo(row);
    setEditModal(true);
  };

  const handleAddModal = (row) => {
    setUserInfo(row);
    setNewUser(true);
    setEditModal(true);
  };

  const handleClose = () => {
    setNewUser(false);
    setEditModal(false);
    setUserInfo(null);
  };

  const handleFilterUsers = (e) => {
    let filtered = usersData;

    const { value } = e.target;
    const accessor = "nome";

    if (value.length > 0) {
      const newData = filtered.filter((r) => {
        return r[accessor]
          .toString()
          .trim()
          .toLowerCase()
          .includes(value.trim().toLowerCase());
      });
      setFilterData(newData);
    } else {
      setFilterData(null);
    }
  };

  const handleOnChange = (value, name) => {
    if (value) {
      if (name === "filial") {
        dispatch(
          setFilter({
            ...query,
            filial: value,
          })
        );
      } else if (name === "status_usuario") {
        dispatch(
          setFilter({
            ...query,
            status_usuario: value,
          })
        );
      }
    }
  };

  const handleUpdateUser = () => {
    requestUsers();
  };

  return (
    <React.Fragment>
      <Filter
        filial={query.filial}
        status={query.status_usuario}
        handleOnChange={handleOnChange}
        handleAddModal={handleAddModal}
      />
      {usersData && (
        <S.Container>
          <Table
            handleFilter={handleFilterUsers}
            style={{ marginTop: 22 }}
            columns={columns}
            data={filterData ? filterData : usersData}
            search="Pesquisar Usuário"
          />
        </S.Container>
      )}
      {userInfo && (
        <Modal
          handleUpdateUser={handleUpdateUser}
          newUser={newUser}
          user={userInfo}
          open={editModal}
          handleClose={handleClose}
        />
      )}
    </React.Fragment>
  );
};

export default Users;
