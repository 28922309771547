import { useState, useEffect } from 'react'

function useBackgroundColor(name) {

    const [background, setBackground] = useState('')
    
    useEffect(() => {

        if(name){
            if(name === 'BAIXA'){
                setBackground('#3699FF20')
            } else if (name === "MÉDIA"){
                setBackground('#FFA80120')
            } else {
                setBackground('#F64E6020')
            }
        }
    }, [name])    
    
    return background
}

export default useBackgroundColor