import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "~/components/Icons";
import * as S from "./styled";

export default function Input({ placeholder, filter, icon, ...props }) {
  const classes = useStyles();

  return (
    <S.StyledTextField
      style={
        filter ? { backgroudColor: "#edf2f9" } : { backgroudColor: "#fff" }
      }
      variant="outlined"
      fullWidth
      placeholder={placeholder}
      className={classes.input}
      InputProps={{ endAdornment: icon && <Icon fill="#95AAC9" name={icon} /> }}
      {...props}
    />
  );
}

const useStyles = makeStyles(() => ({
  input: {
    color: "#494F65",
    "&::placeholder": {
      color: "#95AAC9",
      fontSize: "12px",
      opacity: 1,
      letterSpacing: "0px",
    },
  },
}));
