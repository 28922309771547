import produce from "immer";

const INITIAL_STATE = {
  profile: null,
  company: null,
  userInfo: null
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case "@auth/SIGN_IN_SUCCESS": {
        draft.profile = action.payload.user;
        draft.userInfo = action.payload.userInfo;
        break;
      }
      case "@user/UPDATE_PROFILE_SUCCESS": {
        draft.profile = action.payload.user;
        break;
      }
      case "@auth/SIGN_OUT": {
        draft.profile = null;
        draft.social = null;
        draft.userInfo = null;
        break;
      }
      default:
    }
  });
} 
