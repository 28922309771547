import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "~/components/Icons";
import * as S from "./styled";
import { FormControl, InputLabel } from "@material-ui/core";

export default function InputSelect({
  name,
  onChange,
  value,
  required,
  label,
  children,
  id,
  onClear,
  reset,
  ...props
}) {

  const classes = useStyles();

  return (
    <FormControl className={classes.root} variant="outlined" fullWidth required={required}>
      <InputLabel id={name}>{label}</InputLabel>
      <S.StyledSelect
        required={required}
        {...props}
        labelId={name}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {children}
      </S.StyledSelect>
      {reset && (
        <S.StyledButton onClick={onClear}>
          <Icon name="close" size={20} color="#95AAC9"/>
        </S.StyledButton>
      )}
    </FormControl>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
}))
