import React, { useState, useEffect } from "react";
import * as S from "./styled";
import { Grid, makeStyles } from "@material-ui/core";
import { format, add } from "date-fns";
import Icon from "~/components/Icons";
import ImageModal from "../ImageModal";
import useColor from "~/utils/hooks/useColor";
import useBackgroundColor from "~/utils/hooks/useBackgroundColor";
import { getUserInfo } from "../../services";

export default function Detail({ desvio }) {
  const classes = useStyles();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [userLevel, setUserLevel] = useState(null);

  const handleClose = () => {
    setOpenImageModal(false);
  };

  const formatDate = (date) => {
    return format(new Date(date), "dd/MM/yyyy");
  };

  const formatNewDateHour = (date) => {
    if (date && date !== null) {
      const ndate = new Date(date);
      const date3 = add(ndate, { hours: 3 });
      return format(date3, "dd/MM/yyyy HH:mm");
    } else return "";
  };

  const get_nivel = async () => {
    const res = await getUserInfo();
    if (res.data && res.data.user) setUserLevel(res.data.user.nivel);
  };

  useEffect(() => {
    get_nivel();
  }, []);

  return (
    <>
      <S.Card elevation={0}>
        <S.CardHeader>
          <h1>{`Área: ${desvio ? desvio.area.nome : ""}`}</h1>
          <h1 style={{ marginTop: "5px" }}>{`Filial: ${
            desvio ? desvio.filial.nome : ""
          }`}</h1>
        </S.CardHeader>
        {userLevel !== 5 ? (
          <S.ImageContainer>
            <S.Image
              src={desvio ? desvio.img_ocorrencia : ""}
              alt={desvio ? desvio.img_ocorrencia : ""}
            />
            <S.IconDiv onClick={() => setOpenImageModal(true)}>
              <Icon
                className={classes.icon}
                name={"expand-outline"}
                size={25}
                color={"#BDCC30"}
              />
            </S.IconDiv>
          </S.ImageContainer>
        ) : (
          <div style={{ marginTop: "-30px" }}></div>
        )}
        <S.CardMain>
          <Grid container>
            <Grid item xs={6}>
              <S.CardMainRow>
                <h1>Criticidade:</h1>
              </S.CardMainRow>
            </Grid>
            <Grid item xs={6}>
              <S.CardMainRow>
                <S.Criticidade
                  backgroundColor={useBackgroundColor(
                    desvio ? desvio.tipo.criticidade : ""
                  )}
                  color={useColor(desvio ? desvio.tipo.criticidade : "")}
                >
                  {desvio ? desvio.tipo.criticidade : ""}
                </S.Criticidade>
              </S.CardMainRow>
            </Grid>

            <Grid item xs={6}>
              <S.CardMainRow>
                <h1>Dispositivo:</h1>
              </S.CardMainRow>
            </Grid>
            <Grid item xs={6}>
              <S.CardMainRow>
                <p>{desvio ? desvio.dispositivo.nome : ""}</p>
              </S.CardMainRow>
            </Grid>

            <Grid item xs={6}>
              <S.CardMainRow>
                <h1>Tipo do Dispositivo:</h1>
              </S.CardMainRow>
            </Grid>
            <Grid item xs={6}>
              <S.CardMainRow>
                <p>{desvio ? desvio.dispositivo.tipo : ""}</p>
              </S.CardMainRow>
            </Grid>

            <Grid item xs={6}>
              <S.CardMainRow>
                <h1>Reportado em:</h1>
              </S.CardMainRow>
            </Grid>
            <Grid item xs={6}>
              <S.CardMainRow>
                <p>{desvio && formatDate(desvio.createdAt)}</p>
              </S.CardMainRow>
            </Grid>

            <Grid item xs={6}>
              <S.CardMainRow>
                <h1>Data do desvio:</h1>
              </S.CardMainRow>
            </Grid>
            <Grid item xs={6}>
              <S.CardMainRow>
                <p>{desvio && formatNewDateHour(desvio.data_desvio)}</p>
              </S.CardMainRow>
            </Grid>
          </Grid>
        </S.CardMain>
      </S.Card>
      <ImageModal
        image={desvio ? desvio.img_ocorrencia : ""}
        open={openImageModal}
        handleClose={handleClose}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
    margin: "7px",
    zIndex: 999,
    transform: "scale(1)",
    transition: "0.3s ease-out",
    "&:hover": {
      transform: "scale(1.3)",
    },
  },
}));
