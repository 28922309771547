import { takeLatest, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as types from "./types";

import { setFilterSuccess } from "./actions";

export function* setFilter({ payload }) {
  const { query } = payload;

  try {
    yield put(setFilterSuccess(query));
  } catch (err) {
    toast.error("Erro ao Carregar dados!");
  }
}

export default all([takeLatest(types.SET_FILTER, setFilter)]);
