import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: #95aac9;
  }
  .MuiTextField-root {
    background: "#ffffff";
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: #95aac9;
    }
    &:hover fieldset {
      border-color: #95aac9;
    }
    &.focused fieldset {
      border-color: #95aac9;
    }
  }
  .MuiOutlinedInput-root.Mui-focused fieldset {
    border: 1px solid #95aac9;
  }
  .MuiFormLabel-root {
    background-color: #fff;
    padding-right: 3px;
  }
`;
