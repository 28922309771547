import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "~/components/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as S from "./styled";
import Icon from "~/components/Icons";
import { Collapse, IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import useRequestData from "~/utils/hooks/useRequestData";
import { resetAreaSuccess, resetFilialSuccess } from "~/store/modules/filter/actions";
import { useDispatch } from "react-redux";

export default function Filter({ title, filial, area, handleOnChange }) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const requestFilials = useRequestData("/filials");
  const filials = requestFilials && requestFilials.rows;

  let newAreas;

  if (filials) {
    let newFilials = filials && filials.filter((item) => item.id === filial)[0];
    let filialName = newFilials && newFilials.nome;
    if (filialName) {
      const { areas } = filials.filter(
        (item) => item.nome === String(filialName)
      )[0];
      newAreas = areas && areas;
    }
  }

  return (
    <S.Wrapper>
      <S.Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <S.Title>{title}</S.Title>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <S.More>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
              >
                <Icon
                  name="arrow-circle-down-outline"
                  color="#656E8C"
                  size={18}
                />
              </IconButton>
              <p>
                {expanded
                  ? "Remover e Ocultar Filtros"
                  : "Adicionar e Exibir Filtros"}
              </p>
            </S.More>
          </div>
        </div>
      </S.Header>

      <Collapse
        in={expanded}
        timeout="auto"
        classes={{
          wrapper: classes.alignment,
        }}
        unmountOnExit
      >
        <S.Filter>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Select
                fullwidth
                name="filial"
                label={"Filial"}
                value={filial}
                reset
                onClear={() => dispatch(resetFilialSuccess())}
                onChange={(e) => handleOnChange(e.target.value, "filial")}
              >
                {filials &&
                  filials.map(({ id, nome }) => (
                    <MenuItem value={id}>
                      <S.Item> {nome.toLowerCase()}</S.Item>
                    </MenuItem>
                  ))}
              </Select>
            </Grid>

            <Grid item xs={4}>
              <Select
                fullwidth
                label={"Área"}
                name="area"
                value={area}
                reset
                onClear={() => dispatch(resetAreaSuccess())}
                onChange={(e) => handleOnChange(e.target.value, "area")}
              >
                {newAreas &&
                  newAreas.map((area) => (
                    <MenuItem key={area.id} value={area.id}>
                      <S.Item> {area.nome.toLowerCase()}</S.Item>
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
        </S.Filter>
      </Collapse>
    </S.Wrapper>
  );
}

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    margin: 0,
    padding: 0,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  wrapper: {
    display: "inherit !important",
    width: "100%",
  },
}));
