import produce from "immer";
import * as types from "./types";

const INITIAL_STATE = {
  area: null,
  filial: null,
  tipo: null,
  criticidade: null,
  responsavel: null,
  mesInicial: null,
  anoInicial: null,
  mesFinal: null,
  anoFinal: null,
  label: null,
  viagem: null,
  status_usuario: null,
};

export default function driver(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SET_FILTER_SUCCESS: {
        draft.filial = action.payload.query.filial;
        draft.tipo = action.payload.query.tipo;
        draft.criticidade = action.payload.query.criticidade;
        draft.responsavel = action.payload.query.responsavel;
        draft.area = action.payload.query.area;
        draft.mesFinal = action.payload.query.mesFinal;
        draft.mesInicial = action.payload.query.mesInicial;
        draft.anoInicial = action.payload.query.anoInicial;
        draft.anoFinal = action.payload.query.anoFinal;
        draft.label = action.payload.query.label;
        draft.viagem = action.payload.query.viagem;
        draft.status_usuario = action.payload.query.status_usuario;
        break;
      }

      case types.RESET_DATE_SUCCESS: {
        draft.mesInicial = null;
        draft.mesFinal = null;
        draft.anoInicial = null;
        draft.anoFinal = null;
        draft.label = null;
        break;
      }

      case types.RESET_FILIAL_SUCCESS: {
        draft.area = null;
        draft.filial = null;
        
        break;
      }

      case types.RESET_AREA_SUCCESS: {
        draft.area = null;
        break;
      }

      case types.RESET_CRITICALITY_SUCCESS: {
        draft.criticidade = null;
        break;
      }

      case types.RESET_INFRACTION_SUCCESS: {
        draft.tipo = null;
        break;
      }

      case types.RESET_RESPONSIBLE_SUCCESS: {
        draft.responsavel = null;
        break;
      }

      case types.RESET_VIAGEM_SUCCESS: {
        draft.viagem = null;
        break;
      }

      case types.RESET_STATUS_USUARIO_SUCCESS: {
        draft.status_usuario = null;
        break;
      }

      default:
    }
  });
}
