import React from 'react'
import Chart from 'react-apexcharts'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import * as S from './styled'
import Tabs from '~/components/Tabs'

const useStyles = makeStyles({
  root: {
    width: '100%',
    alignItems: 'center',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 5px 20px #B0C3D329',
    padding: '10px'
  },
})

export default function ChartComponent({ title, mini, tabs, tabsValue, handleTabs, series, options, ...props }) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <S.Header>
        <S.Title>{title}</S.Title>
        {tabs && <Tabs mini={mini} indicatorColor='primary' textColor='primary' items={tabs} value={tabsValue} onChange={handleTabs} />}
      </S.Header>
      <div style={{borderBottom: '1px solid #EDF2F9' , marginBottom: '25px' }}/>
      <Chart options={options} series={series} {...props} />
    </Card>
  )
}
