import React from "react";
import './index.css';

export default function CircularIndeterminate() {
  return (
      <svg
        id="logo"
        width="60px"
        height="60px"
        viewBox="0 0 97 100"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Logo Light"
      >
        <g>
          <title>Layer 1</title>
          <g id="Grupo_62" data-name="Grupo 62">
            <g id="Grupo_55" data-name="Grupo 55">
              <path
                id="Caminho_46"
                data-name="Caminho 46"
                d="m39.14699,99.402a4.755,4.755 0 0 1 -4.756,-4.756l0,-21l-18.19,10.5a4.757,4.757 0 0 1 -4.757,-8.238l19.735,-11.394a8.483,8.483 0 0 1 12.725,7.346l0,22.787a4.756,4.756 0 0 1 -4.757,4.755z"
              />
            </g>
            <g id="Grupo_56" data-name="Grupo 56">
              <path
                id="Caminho_47"
                data-name="Caminho 47"
                d="m57.27901,99.403a4.755,4.755 0 0 1 -4.756,-4.755l0,-22.885a8.483,8.483 0 0 1 12.751,-7.33l19.723,11.482a4.756,4.756 0 0 1 -4.786,8.221l-18.174,-10.582l0,21.092a4.756,4.756 0 0 1 -4.758,4.757z"
                fill="#bdcc30"
              />
            </g>
            <g id="Grupo_57" data-name="Grupo 57">
              <path
                id="Caminho_48"
                data-name="Caminho 48"
                d="m91.666,69.079a4.739,4.739 0 0 1 -2.374,-0.637l-19.735,-11.395a8.483,8.483 0 0 1 0,-14.691l19.735,-11.394a4.756,4.756 0 1 1 4.756,8.237l-18.19,10.5l18.19,10.5a4.756,4.756 0 0 1 -2.381,8.875l-0.001,0.005z"
                fill="#2E3240"
              />
            </g>
            <g id="Grupo_58" data-name="Grupo 58">
              <path
                id="Caminho_49"
                data-name="Caminho 49"
                d="m61.00601,36.03999a8.517,8.517 0 0 1 -8.483,-8.5l0,-22.784a4.756,4.756 0 0 1 9.513,0l0,21l18.19,-10.5a4.756,4.756 0 1 1 4.756,8.238l-19.734,11.394a8.47,8.47 0 0 1 -4.242,1.152z"
                fill="#bdcc30"
              />
            </g>
            <g id="Grupo_59" data-name="Grupo 59">
              <path
                id="Caminho_50"
                data-name="Caminho 50"
                d="m35.42099,36.03999a8.466,8.466 0 0 1 -4.242,-1.151l-19.735,-11.393a4.757,4.757 0 0 1 4.757,-8.238l18.19,10.5l0,-21a4.756,4.756 0 0 1 9.513,0l0,22.787a8.517,8.517 0 0 1 -8.483,8.5l0,-0.005z"
                fill="#bdcc30"
              />
            </g>
            <g id="Grupo_60" data-name="Grupo 60">
              <path
                id="Caminho_51"
                data-name="Caminho 51"
                d="m4.761,69.079a4.757,4.757 0 0 1 -2.383,-8.875l18.192,-10.5l-18.192,-10.5a4.756,4.756 0 1 1 4.757,-8.237l19.735,11.394a8.484,8.484 0 0 1 0,14.693l-19.735,11.393a4.739,4.739 0 0 1 -2.374,0.632zm17.352,-18.485l0,0z"
                fill="#bdcc30"
              />
            </g>
          </g>
        </g>
      </svg>
  );
}
