export const columns = [
  {
    Header: "Descrição",
    accessor: "descricao",
    sortType: "basic",
    show: true,
  },
  {
    Header: "Filial",
    accessor: "filial",
    sortType: "basic",
    show: true,
  },
  {
    Header: "Area",
    accessor: "area",
    sortType: "basic",
    show: true,
  },
  {
    Header: "Tipo",
    accessor: "tipo",
    sortType: "basic",
    show: true,
  },
  {
    Header: "Local",
    accessor: "local",
    sortType: "basic",
    show: true,
  },
  {
    Header: "Última Execução",
    accessor: "processos",
    sortType: "basic",
    show: true,
  },
];
