import React from 'react'
import * as S from './styled'
import CheckFilterImage from '~/images/image_check_filter.png'

export default function CheckFiltersCard() {
  return (
    <S.Container>
      <h3>Nenhum dado retornado</h3>
      <p>
        Ainda não há dados suficientes para está busca. Favor verificar seus
        filtros
      </p>
      <S.Image src={CheckFilterImage} alt={'Imagem Verificar Filtros'} />
    </S.Container>
  )
}
