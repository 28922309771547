import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, MenuItem, FormControl } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import * as S from './styled'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));


function InputSelect({items, onChange, variant, none, fullwidth, value, required, label, ...props}) {

  const classes = useStyles();

  return (
      <FormControl required={required && true} style={{ width: fullwidth && '100%' }} FormControl variant={variant && variant} className={classes.formControl}>
        <InputLabel htmlFor="select-label">{label}</InputLabel>
        <S.StyledSelect
          name="select-label"
          labelId="select-label"
          id="select-label"
          value={value}
          onChange={onChange}
          IconComponent={KeyboardArrowDownIcon}
          label={ label && (<div style={{ fontSize: '0.75rem' }}>{label}</div>)}
          {...props}
        >
          {items && items.map(({ name, value}) => (
            <MenuItem key={name} value={value}>{name}</MenuItem>
          ))}
        </S.StyledSelect>
      </FormControl>
  )

}

export default InputSelect
