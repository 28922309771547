import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import * as S from "./styled";
import InputDate from "../../InputDate";
function FadeMenu({
  one,
  anchorEl,
  clearDate,
  onSubmit,
  handleClose,
  open,
  initialValue,
  finalValue,
  handleFinalDate,
  handleInitialDate,
}) {
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <S.Container>
          <div>
            <InputDate
              dense="dense"
              label={one ? "Data" : "Data Inicial"}
              value={initialValue}
              onChange={(date) => handleInitialDate(date)}
            />
            
            <div style={{ height: "5px" }} />

            { !one && (
            <InputDate
              dense="dense"
              label="Data Final"
              value={finalValue}
              onChange={(date) => handleFinalDate(date)}
            />)
            }
          </div>
          <S.Row>
            <Button
              onClick={clearDate}
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#95AAC9",
                color: "#fff",
              }}
            >
              Limpar Seleção
            </Button>
            <Button
              onClick={onSubmit}
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#BDCC30",
                color: "#fff",
              }}
            >
              Ok
            </Button>
          </S.Row>
        </S.Container>
      </Menu>
    </>
  );
}
export default FadeMenu;
