import { combineReducers } from "redux";

import auth from "./auth/reducer";
import user from "./user/reducer";
import filter from "./filter/reducer";

export default combineReducers({
  auth,
  user,
  filter,
});
