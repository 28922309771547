export default {
  MuiFormControl: {
    root: {
      borderRadius: "4px",
    },
  },
  MuiOutlinedInput: {
    root: {
      borderColor: "#93AAC9 !important",
      color: "#2E3240",
      "& fieldset": {
        borderColor: "#93AAC9 !important",
      },
      "&:hover fieldset": {
        borderColor: "#93AAC9 !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#93AAC9 !important",
      },
    },
  },
  MuiPickersDateRangePickerInput: {
    root: {
      width: "100%",
    },
  },
  MuiInputLabel: {
    root: {
      color: "#93AAC9",
      "&.Mui-focused": {
        color: "#93AAC9",
      },
    },
  },
  MuiInput: {
    root: {
      color: "#2E3240",
    },
  },
  MuiButtonBase: {
    root: {
      boxShadow: "none !important",
      fontSize: "15px !important",
      textTransform: "inherit !important",
      fontWeight: "bold !important",
    },
  },
  MuiAppBar: {
    root: {
      boxShadow: "0px 3px 10px #95AAC91A; !important",
    },
  },
  MuiSvgIcon: {
    root: {
      color: "#93AAC9 !important",
    },
  },
  MuiFormLabel: {
    asterisk: {
      color: "#db3131",
      fontWeight: "bold",
      "&$error": {
        color: "#db3131",
      },
    },
  },
};
