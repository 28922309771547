import React from 'react'
import * as S from './styled'
import Icon from '~/components/Icons'
import Select from '~/components/Inputs/InputSelect'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useTable, useSortBy, usePagination, useFilters } from 'react-table'
import CheckFiltersCard from '../Cards/CheckFiltersCard'

const TableRanking = ({
  center,
  loadingExcel,
  successExcel,
  columns,
  data,
  title,
  search,
  exportTo,
  initialState,
  headerEnd,
  onClickRow,
  handleFilter,
  permitIsSortedOccur,
  handleInfo,
  noPagging,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { initialState, pageSize: 20 },
    },
    useFilters,
    useSortBy,
    usePagination
  )

  return (
    <S.Wrapper {...props}>
      <S.Header noPagging={noPagging}>
        {title && (
          <S.HeaderCol>
            <h1>{title}</h1>
          </S.HeaderCol>
        )}
        {search && (
          <S.HeaderCol>
            <S.Search>
              <input
                style={{ backgroundColor: '#F9FBFD' }}
                onChange={handleFilter}
                placeholder={search}
              />
              <Icon name='search' size={14} />
            </S.Search>
          </S.HeaderCol>
        )}
      </S.Header>
      {data && data?.length > 0 ? (
        <>
          <S.Table {...getTableProps()}>
            <S.Head>
              {headerGroups.map(headerGroup => (
                <S.Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    return (
                      <S.Th
                        center={center}
                        {...column.getHeaderProps(
                          permitIsSortedOccur && column.getSortByToggleProps()
                        )}
                      >
                        <S.Title>
                          {column.render('Header')}
                          {permitIsSortedOccur && (
                            <span>
                              {permitIsSortedOccur && column.isSorted ? (
                                column.isSortedDesc ? (
                                  <ArrowDropUpIcon fontSize='small' />
                                ) : (
                                  <ArrowDropDownIcon fontSize='small' />
                                )
                              ) : (
                                ''
                              )}
                            </span>
                          )}
                          {handleInfo && (
                            <S.InfoIcon
                              onClick={() => handleInfo('POS CÂMERA')}
                            >
                              {column.Header === 'POS CÂMERA' && (
                                <ErrorOutlineIcon
                                  style={{ marginLeft: '5px' }}
                                  fontSize='small'
                                />
                              )}
                            </S.InfoIcon>
                          )}
                          {handleInfo && (
                            <S.InfoIcon
                              onClick={() => handleInfo('CANAIS DVR')}
                            >
                              {column.Header === 'CANAIS DVR' && (
                                <ErrorOutlineIcon
                                  style={{ marginLeft: '5px' }}
                                  fontSize='small'
                                />
                              )}
                            </S.InfoIcon>
                          )}
                        </S.Title>
                      </S.Th>
                    )
                  })}
                </S.Tr>
              ))}
            </S.Head>
            {data && page && (
              <S.Body {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <S.Tr
                      {...row.getRowProps(
                        onClickRow && {
                          onClick: () => onClickRow(row),
                        }
                      )}
                    >
                      {row.cells.map(cell => {
                        return (
                          <S.Td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </S.Td>
                        )
                      })}
                    </S.Tr>
                  )
                })}
              </S.Body>
            )}
          </S.Table>
          <S.Footer>
            <S.FooterCol>
              <p>Exibindo</p>
              <Select
                value={pageSize}
                variant='outlined'
                margin='dense'
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                items={[
                  { name: '10', value: 10 },
                  { name: '20', value: 20 },
                  { name: '30', value: 30 },
                ]}
                style={{
                  minWidth: 80,
                }}
              />
              <p>{`de ${data.length}`}</p>
            </S.FooterCol>
            {data.length > pageSize && (
              <S.FooterCol>
                <S.Control onClick={() => gotoPage(0)}>
                  <Icon name='chevron-left-outline' />
                  <p>Primeiro</p>
                </S.Control>
                <S.Control onClick={() => previousPage()}>
                  <Icon name='chevron-left-outline' />
                  <p>Anterior</p>
                </S.Control>
                <S.Control onClick={() => nextPage()}>
                  <p>Próximo</p>
                  <Icon name='chevron-right-outline' />
                </S.Control>
                <S.Control onClick={() => gotoPage(pageCount - 1)}>
                  <p>Último</p>
                  <Icon name='chevron-right-outline' />
                </S.Control>
              </S.FooterCol>
            )}
          </S.Footer>
        </>
      ) : (
        <CheckFiltersCard />
      )}
    </S.Wrapper>
  )
}

export default TableRanking
