import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const Card = styled(Paper)`
  padding: 24px;
  height: 546px;
  background: #ffffff;
  box-shadow: 0px 5px 20px #b0c3d329;
  border-radius: 5px;
  opacity: 1;
`;

export const CardHeader = styled.div`
  margin-bottom: 20px;
  padding: 0;

  h1 {
    color: #494f65;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
  }

  h2 {
    color: #95aac9;
    font-size: 13px;
  }
`;

export const CardMain = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 0;
`;

export const CardMainRow = styled.div`
  display: flex;
  padding: 10px 0;

  h1 {
    font-size: 16px;
    font-weight: 600;
    color: #95aac9;
  }

  p {
    color: #2e3240;
    font-size: 16px;
  }
`;
export const ImageContainer = styled.div`
  border-radius: 4px;
  width: 100%;
  max-width: 295px;
  height: 201px;
  position: relative;
  transition: 0.3s;
  background: transparent;
  z-index: 20;
`;

export const Image = styled.img`
  position: absolute;
  z-index: 40;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

export const IconDiv = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
`;

export const Criticidade = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  min-width: 60px;
  height: 25px;
  background: ${(props) => props.backgroundColor};
  border-radius: 10px;
  color: ${(props) => props.color};
  font-size: 13px;
  font-weight: 700;
`;
