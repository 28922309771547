import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components'

export default function CustomizedButtons({ loading, success, children, icon, disabled, ...props }) {

  return (
      <Wrapper 
        disabled={disabled}
        off={disabled}
      startIcon={icon && icon}
        {...props}
      >
        {loading ? <CircularProgress size={24} /> : children}
      </Wrapper>
  );
}

const Wrapper = styled(Button)`
  ${({ off }) => off && `
      background: #f3f3f3 !important;
  `}
`