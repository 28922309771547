import styled from "styled-components";

export const Container = styled.div``;

export const Main = styled.div`
  margin-top: 18px;
`;

export const Title = styled.h1`
  font-size: 24px;
  color: #494f65;
  margin-top: -15px;
  margin-bottom: 5px;
  font-weight: 900;
  letter-spacing: 1.1px;
`;

export const Header = styled.div`
  padding: 22px 0px 0px 0px;
`;

export const Position = styled.div`
  font-weight: 600;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 20px;
  font-size: 12px;
  margin-left: 10px;

  ${({ position }) =>
    position <= 4 &&
    `
    border: 1px solid #e45;
    color: #e45;
  `}

  /* ${({ position, total }) =>
    position > total - 11 &&
    `
    background-color: #F64E6015;
    border: 1px solid #00e676;
    color: #00e676;
    background-color: #e8f5e9;

  `} */
`;

export const PositionRow = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: ${({ center }) => (center ? "20px" : "0")};

  ${({ position }) =>
    position <= 4 &&
    `    color: #e45;
  `}

  /* ${({ position, total }) =>
    position > total - 11 &&
    `
    background-color: #F64E6015;
    border: 1px solid #00e676;
    color: #00e676;
    background-color: #e8f5e9;

  `} */
`;
