import api from '../../services/api'

const requestAvailable = async (query) => {
  const res = await api.get('/available-videos', { params: query })
  return res
}

const createAvailable = async (data) => {
  const res = await api.post('/available-videos', data)
  return res
}

const requestFilials = async() => {
  const res = await api.get('/filials')
  return res
}

export { requestAvailable, requestFilials, createAvailable  }