import produce from "immer";

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  user: null
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true
        break
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token
        draft.signed = true
        draft.loading = false
        draft.user = action.payload.userInfo
        break
      }
      case '@auth/SIGN_UP_REQUEST': {
        draft.loading = true
        break
      }
      case '@auth/SIGN_UP_SUCCESS': {
        draft.loading = false
        break
      }
      case '@auth/SIGN_FAILURE': {
        draft.loading = false
        break
      }
      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.user = null;
        draft.signed=  false;
        break;
      }
      case '@auth/REFRESH_REQUEST': {
        draft.token = action.payload.token
        break
      }
      default:
        return state
    }
  });
}
