import React, { useState, useEffect } from "react";
import * as S from "./styled";
import { Grid } from "@material-ui/core";
import Chart from "~/components/Chart";
import Tabs from "~/components/Tabs";
import { useDispatch, useSelector } from "react-redux";
import Loading from "~/components/Loading";
import Table from "~/components/Table";
import Filter from "./Filtros";
import { setFilter } from "../../store/modules/filter/actions";
import useRequestData from '~/utils/hooks/useRequestData'
import {
  radarOptions,
  optionsCriticality,
  optionsStatus,
  historicoPontosPorHora,
  columnsRanking,
} from "./constants";
import api from "~/services/api";

const Dashboard = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);
  const [tab, setTab] = useState(0);
  // const [filteredData, setFilteredData] = useState(null);
  const filteredData = null
  const [graphicOne, setGraphicOne] = useState(null);
  const [graphicPontosMes, setGraphicPontosMes] = useState(null);
  const [graphicDesviosMes, setGraphicDesviosMes] = useState(null);
  const [graphicPontosFilial, setGraphicPontosFilial] = useState(null);
  const [graphicTripPointsPerHour, setGraphicTripPointsPerHour] = useState(null)

  const [rankingData, setRankingData] = useState(null);

  const query = useSelector((state) => state.filter);

  const requestFilials = useRequestData('/filials')
  const filials = requestFilials && requestFilials.rows

  const requestGraphRadarDesvios = async (data) => {
    const response = await api.get("/dashboard/1", { params: data });
    setGraphicOne(response.data);
  };

  const requestGraphPontosMes = async (data) => {
    const response = await api.get("/dashboard/2", { params: data });
    setGraphicPontosMes(response.data);
  };

  const requestGraphPontosFilial = async (data) => {
    const response = await api.get("/dashboard/3", { params: data });
    setGraphicDesviosMes(response.data);
  };

  const requestGraphicDesviosMes = async (data) => {
    const response = await api.get("/dashboard/4", { params: data });
    setGraphicPontosFilial(response.data);
  };

  const requestGraphicTripPointsPerHour = async (data) => {
    if (data && data.area === null) setGraphicTripPointsPerHour(null);
    else {
      const response = await api.get("/dashboard/5", { params: data });
      setGraphicTripPointsPerHour(response.data.points_per_trip);
    }
  };

  const requestRankingData = async (data) => {
    const response = await api.get("/ranking", { params: data });
    setRankingData(response.data);
  };

  useEffect(() => {
    requestGraphRadarDesvios(query);
    requestGraphPontosMes(query);
    requestGraphPontosFilial(query);
    requestGraphicDesviosMes(query);
    requestRankingData(query);
    requestGraphicTripPointsPerHour(query)
  }, [query]);

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  columnsRanking.forEach((i) => {
    if (i.accessor === "posicao") {
      i.Cell = ({ row }) => {
        return (
          <S.Position position={row.original.position}>
            <p style={{ marginTop: "-2px" }}>{row.original.position}</p>
          </S.Position>
        );
      };
    } else if (i.accessor === "area") {
      i.Cell = ({ row }) => (
        <S.PositionRow position={row.original.position}>
          {row.original.area}
        </S.PositionRow>
      );
    } else if (i.accessor === "filial") {
      i.Cell = ({ row }) => (
        <S.PositionRow position={row.original.position}>
          {row.original.filial}
        </S.PositionRow>
      );
    } else if (i.accessor === "desvios") {
      i.Cell = ({ row }) => (
        <S.PositionRow center position={row.original.position}>
          {row.original.areaAmountOfInfractions}
        </S.PositionRow>
      );
    } else if (i.accessor === "pontos") {
      i.Cell = ({ row }) => (
        <S.PositionRow center position={row.original.position}>
          {row.original.areaPoints}
        </S.PositionRow>
      );
    } else if (i.accessor === "responsible") {
      i.Cell = ({ row }) => (
        <S.PositionRow position={row.original.position}>
          {row.original.areaResponsible}
        </S.PositionRow>
      );
    }
  });

  let per_type = [];
  if (graphicOne && graphicOne.per_type) {
    per_type = graphicOne.per_type.sort((a, b) => b.y - a.y);
  }

  let newType = per_type.map((item) => {
    let type = { x: item.label, y: item.value };
    return type;
  });

  let newGravity = null;
  if (graphicOne && graphicOne.per_gravity) {
    newGravity = graphicOne.per_gravity.map((item) => {
      let gravity = { name: item.label, data: [item.value] };
      return gravity;
    });
  }

  let newStatus = null;
  if (graphicOne && graphicOne.per_status) {
    newStatus = graphicOne.per_status.map((item) => {
      let status = { name: item.label, data: [item.value] };
      return status;
    });
  }

  //@ SECOND_GRAPHIC

  let pts_hr = [];
  let per_mth = [];

  if (graphicPontosMes && graphicPontosMes.per_month) {
    pts_hr = graphicPontosMes.per_month.map((item) => item.value);
    per_mth = graphicPontosMes.per_month.map((item) => item.label);
  }

  //@ THIRD_GRAPHIC

  let per_filial = [];
  if (graphicPontosFilial && graphicPontosFilial.per_filial) {
    per_filial = graphicPontosFilial.per_filial.sort((a, b) => b.y - a.y);
  }

  let newFilial = per_filial.map((item) => {
    let filial = { x: item.label, y: item.points };
    return filial;
  });

  let points_per_trip = [];

  if (graphicTripPointsPerHour) {
    points_per_trip = graphicTripPointsPerHour.map((item) => {
      let travel = { x: item.label, y: item.points };
      return travel;
    });
  }

  //@ FOURTH_GRAPHIC

  let pts_hr_desvios = [];
  let per_mth_desvios = [];
  if (graphicDesviosMes && graphicDesviosMes.per_month) {
    pts_hr_desvios = graphicDesviosMes.per_month.map((item) => item.value);
    per_mth_desvios = graphicDesviosMes.per_month.map((item) => item.label);
  }

  const handleOnChange = (value, name) => {
    if (name === "filial") {
      dispatch(
        setFilter({
          ...query,
          filial: value,
        })
      );
    }
    if (name === "area") {
      dispatch(
        setFilter({
          ...query,
          area: value,
        })
      );
    }
  };

  return (
    <>
      {graphicOne &&
        graphicPontosMes &&
        graphicDesviosMes &&
        filials &&
        graphicPontosFilial ? (
        <S.Container>
          <Filter
            title={"Dashboard"}
            handleOnChange={handleOnChange}
            filteredData={filteredData}
            filial={query.filial}
            filials={filials}
            area={query.area}
          />
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTab}
            items={["GRÁFICOS", "RANKING"]}
          />
          <S.Main>
            {tab === 0 ? (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {value === 0 && newType && (
                    <Chart
                      mini={true}
                      title="Radar de Desvios"
                      tabs={["POR TIPO", "POR CRITICIDADE", "POR STATUS"]}
                      tabsValue={value}
                      handleTabs={handleChange}
                      type="bar"
                      series={[{ name: "Tipo", data: newType }]}
                      options={radarOptions}
                    />
                  )}
                  {value === 1 && newGravity && (
                    <Chart
                      mini={true}
                      title="Radar de Desvios"
                      tabs={["POR TIPO", "POR CRITICIDADE", "POR STATUS"]}
                      tabsValue={value}
                      handleTabs={handleChange}
                      series={newGravity}
                      options={optionsCriticality}
                      type="bar"
                    />
                  )}
                  {value === 2 && newStatus && (
                    <Chart
                      mini={true}
                      title="Radar de Desvios"
                      tabs={["POR TIPO", "POR CRITICIDADE", "POR STATUS"]}
                      tabsValue={value}
                      handleTabs={handleChange}
                      series={newStatus}
                      options={optionsStatus}
                      type="bar"
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {pts_hr && per_mth && (
                    <Chart
                      title="Histórico de Pontuação"
                      tabsValue={0}
                      series={[{ name: "Pontos/h", data: pts_hr }]}
                      options={{
                        ...historicoPontosPorHora,
                        xaxis: {categories: per_mth },
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {pts_hr_desvios && per_mth_desvios && (
                    <Chart
                      title="Histórico Desvios"
                      tabsValue={0}
                      series={[{ name: "Pontos/h", data: pts_hr_desvios }]}
                      options={{
                        ...historicoPontosPorHora,
                        xaxis: { categories: per_mth_desvios },
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {newFilial && (
                    <Chart
                      type="bar"
                      title="Histórico de Pontuação por Filiais"
                      tabsValue={0}
                      series={[
                        { name: "Pontuação por Filial", data: newFilial },
                      ]}
                      options={radarOptions}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>

                  <Chart
                    type="bar"
                    title="Histórico de Pontuação por Viagem"
                    tabsValue={0}
                    series={[
                      { name: "Pontuação por Viagem", data: points_per_trip },
                    ]}
                    options={radarOptions}
                  />

                </Grid>
              </Grid>
            ) : (
              <S.Container>
                <Table
                  style={{ marginTop: 16 }}
                  columns={columnsRanking}
                  data={rankingData.rows}
                />
              </S.Container>
            )}
          </S.Main>
        </S.Container>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Dashboard;
