import React, { useRef, useState, useEffect } from "react";
import * as S from "./styled";
import Loading from "~/components/Loading";
import { Grid, MenuItem } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Select from "~/components/Select";
import Tabs from "~/components/Tabs";
import Button from "~/components/Buttons";
import Icon from "~/components/Icons";
import Input from "~/components/Input";
import { SaveModal as Aprove } from "~/components/Cards/Save/SaveModal";
import { SaveModal as Save } from "~/components/Cards/Save/SaveModal";
import Remove from "~/components/Cards/Remove/Remove";
import DatePicker from "~/components/Inputs/InputDate";
import Header from "./HeaderDetail";
import InfoDriverCard from "./InfoDriverCard";
import useRequestData from "~/utils/hooks/useRequestData";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteDesvio, updateDesvio, requestDesvio } from "../services";

const howStatus = (status, aprovar) => {
  if (aprovar) {
    if (status === "PENDENTE") {
      return "FINALIZADO";
    } else {
      return "PENDENTE";
    }
  } else {
    return status;
  }
};

export default function Detail() {
  const theme = useTheme();
  const history = useHistory();
  const useFormRef = useRef(null);
  const { id } = useParams();

  const requestUsers = useRequestData("/users");
  const requestInfractionTypes = useRequestData("/infractionTypes");

  const users = requestUsers && requestUsers.rows;
  const typeInfractions = requestInfractionTypes;

  const [desvio, setDesvio] = useState(null);
  const [openSave, setOpenSave] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [aprovar, setAprovar] = useState(null);
  const [motive, setMotive] = useState("");
  const [type, setType] = useState(null);

  useEffect(() => {
    const fetchDesvio = async () => {
      const res = await requestDesvio(id);
      if (res.data) setDesvio(res.data);
      else toast.error("Erro ao carregar desvio!");
    };
    if (typeInfractions) {
      fetchDesvio();
    }
  }, [id, typeInfractions]);

  const formik = useFormik({
    initialValues: {
      id_tipo_desvio: desvio && desvio.tipo.id,
      data_desvio: desvio && desvio.data_desvio,
      plano_de_acao: desvio && desvio.plano_de_acao,
      observacoes: desvio && desvio.observacoes,
      data_previsao: desvio && desvio.data_previsao,
      id_do_usuario: desvio && desvio.id_do_usuario,
    },
    onSubmit: (values) => {
      if (values) handleSubmitForm(values);
    },
  });

  const handleForm = (type) => {
    if (formik.handleSubmit()) {
      useFormRef.current.formik.handleSubmit();
    }
    setType(type);
  };

  //@SUBMIT FORM
  const handleSubmitForm = async (data) => {
    if (data.id_do_usuario && data.data_previsao && data.plano_de_acao) {
      const desvioSubmit = desvio;
      desvioSubmit.data_desvio =
        data.data_desvio !== null ? new Date(data.data_desvio) : null;
      desvioSubmit.data_previsao =
        data.data_previsao !== null ? new Date(data.data_previsao) : null;
      desvioSubmit.id_do_usuario = data.id_do_usuario;
      desvioSubmit.id_tipo_desvio = data.id_tipo_desvio;
      desvioSubmit.observacoes = data.observacoes;
      desvioSubmit.plano_de_acao = data.plano_de_acao;
      let date = new Date();
      desvioSubmit.updatedAt = date;
      const newType =
        typeInfractions &&
        typeInfractions.filter(
          (infraction) => infraction.id === data.id_tipo_desvio
        )[0];
      newType.createdAt = desvioSubmit.tipo.createdAt;
      newType.updatedAt = date;
      if (newType) {
        desvioSubmit.tipo = newType;
      }

      if (type === "aprovar") {
        desvioSubmit.status = howStatus(desvio.status, aprovar);
        const res = await updateDesvio(desvioSubmit);
        if (res.data.success) {
          toast.success("Desvio aprovado com sucesso");
          setAprovar(false);
        } else {
          if (res?.data?.message) toast.error(res?.data?.message);
          else toast.error("Erro ao aprovar desvio");
          setAprovar(false);
        }

        setAprovar(false);
        setType(null);
      } else if (type === "salvar") {
        desvioSubmit.status = desvio.status;
        const res = await updateDesvio(desvioSubmit);
        if (res.data.success) {
          toast.success("Desvio salvo com sucesso");
          setOpenSave(false);
          setType(null);
        } else {
          toast.error("Erro ao salvar o desvio");
        }
      }
    } else {
      toast.error(
        "Plano de ação, Previsão de Conclusão e Responsável são campos obrigatórios"
      );
      setOpenSave(false);
      setAprovar(false);
      setType(null);
    }
  };

  // Atualiza variaveis do formik
  if (desvio && formik && formik.values) {
    if (!formik.values.data_desvio)
      formik.setFieldValue("id_tipo_desvio", desvio.id_tipo_desvio);
    if (!formik.values.data_desvio)
      formik.setFieldValue("data_desvio", desvio.data_desvio);
    if (!formik.values.data_desvio)
      formik.setFieldValue("plano_de_acao", desvio.plano_de_acao);
    if (!formik.values.data_desvio)
      formik.setFieldValue("observacoes", desvio.observacoes);
    if (!formik.values.data_desvio)
      formik.setFieldValue("data_previsao", desvio.data_previsao);
    if (!formik.values.data_desvio)
      formik.setFieldValue("id_do_usuario", desvio.id_do_usuario);
  }

  //@DELETE
  const handleDelete = async () => {
    if (motive) {
      const data = { ...desvio, motivo_exclusao: motive, status: "DELETADO" };
      const res = await deleteDesvio(data);
      if (res.data.success) {
        toast.success("Desvio deletado com sucesso");
        setOpenRemove(false);
        setMotive("");
      } else {
        if (res?.data?.message) toast.error(res?.data?.message);
        else toast.error("Erro ao deletar desvio");
      }
    } else {
      toast.error("Erro ao deletar desvio");
    }
  };

  return !desvio ? (
    <Loading />
  ) : (
    <S.Wrapper>
      <Header desvio={desvio} />
      <S.Main>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <InfoDriverCard desvio={desvio} />
          </Grid>
          <Grid item xs={12} md={8} style={{ width: "100%" }}>
            <S.Card elevation={0}>
              <Tabs
                style={{ marginTop: "-10px" }}
                value={0}
                indicatorColor="primary"
                textColor="primary"
                items={["DADOS DO DESVIO"]}
              />
              <form
                ref={useFormRef}
                onSubmit={formik.handleSubmit}
                setFieldValue
                enableReinitialize
              >
                <S.CardMain>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Select
                        name="id_tipo_desvio"
                        variant="outlined"
                        fullwidth
                        label="Desvio"
                        value={formik.values.id_tipo_desvio}
                        onChange={formik.handleChange}
                      >
                        {typeInfractions &&
                          typeInfractions.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              <S.Item>{item.descricao.toLowerCase()}</S.Item>
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        datePicker
                        name="data_desvio"
                        variant="outlined"
                        fullwidth
                        label="Data de Desvio"
                        value={formik.values.data_desvio}
                        onChange={(date) =>
                          formik.setFieldValue("data_desvio", date)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        required
                        name="plano_de_acao"
                        variant="outlined"
                        fullwidth
                        label="Plano de Ação"
                        multiline
                        maxRows={4}
                        value={formik.values.plano_de_acao}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        name="observacoes"
                        variant="outlined"
                        fullwidth
                        label="Observações"
                        multiline
                        maxRows={4}
                        value={formik.values.observacoes}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        future
                        required
                        datePicker
                        name="data_previsao"
                        variant="outlined"
                        fullwidth
                        label="Previsão de Conclusão"
                        value={formik.values.data_previsao}
                        onChange={(date) =>
                          formik.setFieldValue("data_previsao", date)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        required
                        fullwidth
                        label="Responsável"
                        name="id_do_usuario"
                        value={formik.values.id_do_usuario}
                        onChange={formik.handleChange}
                      >
                        {users &&
                          users.map((r) => {
                            return (
                              <MenuItem value={r.id}>
                                <S.Item>{r.nome.toLowerCase()}</S.Item>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Grid>
                  </Grid>
                </S.CardMain>
              </form>
            </S.Card>
          </Grid>
        </Grid>
      </S.Main>
      <S.Footer>
        {desvio && desvio.status !== "DELETADO" ? (
          <Button
            onClick={() => setOpenRemove(true)}
            startIcon={
              <Icon
                name="trash-2-outline"
                color={theme.palette.secondary.main}
              />
            }
            color="secondary"
            variant="outlined"
          >
            Excluir
          </Button>
        ) : (
          <div></div>
        )}
        <div>
          <Button
            onClick={() => history.push("/desvios")}
            startIcon={
              <Icon
                name="corner-up-left-outline"
                color={theme.palette.grey.main}
              />
            }
            variant="outlined"
            style={{
              borderColor: theme.palette.grey.main,
              color: theme.palette.grey.main,
            }}
          >
            Cancelar e Voltar
          </Button>
          {desvio &&
            desvio.status !== "FINALIZADO" &&
            desvio.status !== "DELETADO" && (
              <Button
                onClick={() => setAprovar(true)}
                startIcon={
                  <Icon name="checkmark-circle-2-outline" color="#fff" />
                }
                color="primary"
                variant="contained"
                style={{
                  color: "#fff",
                }}
              >
                {desvio.status === "PENDENTE"
                  ? "Finalizar Ocorrência"
                  : "Aprovar Ocorrência"}
              </Button>
            )}
          <Button
            onClick={() => setOpenSave(true)}
            startIcon={<Icon name="save-outline" color="#fff" />}
            variant="contained"
            color="primary"
            style={{
              color: "#fff",
            }}
          >
            Salvar
          </Button>
        </div>
      </S.Footer>
      <Remove
        onClick={handleDelete}
        setMotive={setMotive}
        motive={motive}
        open={openRemove}
        handleClose={() => setOpenRemove(false)}
        title={"desvio"}
      />
      <Aprove
        onClick={() => handleForm("aprovar")}
        open={aprovar}
        handleClose={() => setAprovar(false)}
        title={"desvio"}
        subtitle={"do desvio"}
      />
      <Save
        onClick={() => handleForm("salvar")}
        open={openSave}
        handleClose={() => setOpenSave(false)}
        header={"salvar"}
        title={"desvio"}
        subtitle={"do desvio"}
      />
    </S.Wrapper>
  );
}
