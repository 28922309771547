import React, { useEffect } from 'react'
import * as eva from 'eva-icons'

export default function Icons({ name, animation, hover, infinite, size, color, ...props }){

  useEffect(() => {
    eva.replace();
  }, []);

  return(
      <i
      data-eva={name}
      data-eva-animation={animation}
      data-eva-hover={hover}
      data-eva-infinite={infinite}
      data-eva-height={size || 24}
      data-eva-width={size || 24}
      data-eva-fill={color || '#486F88'}
      {...props}
    />
  )
}